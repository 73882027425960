import {Component, ElementRef, OnInit} from '@angular/core';
import {SessionService} from '../core/services/session.service';
import {Observable} from 'rxjs';
import {ClickService} from '../core/services/click.service';
import {EnvService} from '../core/services/env.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title: string = 'app';

  loggedIn$: Observable<null | boolean>;


  constructor(
    private $el: ElementRef,
    private env: EnvService,
    private session: SessionService,
    private clickService: ClickService,
  ) {
  }


  ngOnInit() {
    this.loggedIn$ = this.session.isAuthenticated$;

    /**
     * Do not remove this. It serves the entire app, anywhere a component is listening
     * for a document-level click.
     *
     * @param $event
     */
    this.$el.nativeElement.addEventListener('mousedown', ($event: { target: HTMLElement; }) => {
      this.clickService.click$.next($event.target);
    });
  }
}
