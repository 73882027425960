import {Injectable} from '@angular/core';
import {CanActivate, CanActivateChild, Router} from '@angular/router';
import {SessionService} from '../services/session.service';
import {filter, Observable, take} from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class AuthedGuard implements CanActivate, CanActivateChild {
  constructor(
    private session: SessionService,
    private router: Router,
  ) {
  }


  canActivate(): Observable<boolean> {
    return new Observable<boolean>(subscriber => {
      if (this.session.isAuthenticated === true) {
        subscriber.next(true);
        subscriber.complete();
      } else if (this.session.isAuthenticated === false) {
        this.navigateToLogin();
        subscriber.next(false);
        subscriber.complete();
      }

      this.session.isAuthenticated$.pipe(
        filter((a) => {
            return a !== null;
          },
        ),
        take(1),
      ).subscribe({
        next: (authed) => {
          if (!authed) {
            this.navigateToLogin();
          }

          subscriber.next(!!authed);
          subscriber.complete();
        },
      });
    });
  }


  navigateToLogin() {
    this.router.navigate(['/login']);
  }


  canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }
}
