import {Component, ElementRef, Input, Renderer2, ViewChild} from '@angular/core';
import {SessionService} from '../../../core/services/session.service';


@Component({
  selector: 'kallo-platform-wrapper',
  templateUrl: './platform-wrapper.component.html',
  styleUrls: ['./platform-wrapper.component.scss'],
})
export class PlatformWrapperComponent {
  @Input() loggedIn: null | boolean;

  private readonly transitionTime: string;

  @ViewChild('mainContent', {static: true}) private mainContent!: ElementRef;


  constructor(
    private session: SessionService,
    private renderer: Renderer2,
  ) {
    const style = getComputedStyle(document.body);
    this.transitionTime = style.getPropertyValue('--kallo-standard-transition');
  }


  ngOnInit() {
    this.session.isAuthenticated$.subscribe({
      next: (loggedIn) => {
        setTimeout(() => {
          if (loggedIn) {
            this.renderer.setStyle(this.mainContent.nativeElement, 'transition', `margin-left ${this.transitionTime}, width ${this.transitionTime}`);
          } else {
            this.renderer.setStyle(this.mainContent.nativeElement, 'transition', '');
          }
        });
      },
    });
  }
}
