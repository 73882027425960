import {Component, OnInit} from '@angular/core';
import {Link} from "../../models/link";
import {PageTitle} from "../../models/page-title";
import {PageTitleService} from "../../../core/services/page-title.service";


@Component({
  selector: 'kallo-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})
export class TitleComponent implements OnInit {
  crumbs!: Link[] | null;

  title?: string | null = null;
  description?: string | null = null;
  imageSrc?: string | null = null;

  buttonConfig?: Link[] | null;
  buttonClicked: boolean[];


  constructor(private pageTitleService: PageTitleService) {
  }


  ngOnInit() {
    this.pageTitleService.pageTitle$.subscribe({
      next: (pageTitle) => {
        setTimeout(() => {
          this.hydrate(pageTitle);
        }, 0);
      },
    });
  }


  private hydrate(pageTitle: PageTitle) {
    this.crumbs = pageTitle.crumbs;
    this.title = pageTitle.title;
    this.description = pageTitle.description;
    this.imageSrc = pageTitle.imageSrc;
    this.buttonConfig = pageTitle.buttonConfig;

    if (this.buttonConfig?.length) {
      this.buttonClicked = [];

      for (let x = 0; x < this.buttonConfig.length; x++) {
        this.buttonClicked.push(false);
      }
    }
  }


  onButtonClick(button: Link, index: number) {
    if (button.callback) {
      this.buttonClicked[index] = true;
      button.callback();
    }
  }
}
