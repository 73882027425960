import {Component, ElementRef, ViewChild} from '@angular/core';
import {ShadeService} from '../../../core/services/shade.service';
import {UserService} from '../../../core/services/user.service';


@Component({
  selector: 'kallo-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  copyrightYear: string;
  invitationEmailAddress: string = '';

  @ViewChild('invitationSendButton', {read: ElementRef}) $newsletterSend: ElementRef<HTMLElement>;


  constructor(
    private shade: ShadeService,
    private userService: UserService,
  ) {
    this.copyrightYear = this.getCopyrightYear();
  }


  submitInvitationForm() {
    this.$newsletterSend.nativeElement.classList.add('sent');

    this.userService.sendInvitation$(this.invitationEmailAddress).subscribe({
      next: () => {
        this.shade.startDialog(
          'Sent',
          'Your invitation was sent successfully!',
        );
        this.$newsletterSend.nativeElement.classList.remove('sent');
        this.invitationEmailAddress = '';
      },
      error: () => {
        this.shade.errorDialog();
        this.$newsletterSend.nativeElement.classList.remove('sent');
      },
    });
  }


  private getCopyrightYear(): string {
    const startYear: number = 2023;

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    if (startYear === currentYear) {
      return `${startYear}`;
    }

    return `${startYear}–${currentYear}`;
  }


}
