<div>
  <div
    class="progress"
    [ngStyle]="{width: (numerator / denominator*100) + '%'}"
  ></div>

  <div
    class="tally"
    *ngIf="numerator / denominator"
  >
    {{(numerator / denominator * 100).toFixed(0)}}%
  </div>

  <div
    class="tally"
    *ngIf="!numerator"
  >
    {{zeroProgressText}}
  </div>
</div>
