import {Component} from '@angular/core';


interface LeftNavItem {
  href: string;
  text: string;
  icon: string;
}


@Component({
  selector: 'kallo-left-nav-drawer',
  templateUrl: './left-nav-drawer.component.html',
  styleUrls: ['./left-nav-drawer.component.scss'],
})
export class LeftNavDrawerComponent {
  items: LeftNavItem[] = [
    {
      href: '/home',
      text: 'Home',
      icon: 'fa-house-blank',
    },
    {
      href: '/convos',
      text: 'Convos',
      icon: 'fa-comments',
    },
    {
      href: '/design-language',
      text: 'Design Language',
      icon: 'fa-object-group',
    },
  ];
}
