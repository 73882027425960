import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {DIALOG_BOX_BUTTON_OK, DialogBoxButton} from '../../lib/models/dialog-box';

export const SHADE_DIALOG_BOX: ShadeToolType = 'SHADE_DIALOG_BOX';

export type ShadeToolType = ''
  | 'SHADE_DIALOG_BOX';


/**
 * These are all the possible properties that can be used across all types of
 * shade tools. If two tools use the same property, it's only incidental and
 * we can just reuse the property name since only one shade tool can appear at
 * a time.
 */
export interface ShadeServiceProps {
  useCase?: string;
  header?: string;
  message?: string;
  messageHtml?: string;
  dialogOptions?: DialogBoxButton[];
  enableInput?: boolean;
  password?: boolean;
  closeOnButtonClick?: boolean;
}


@Injectable({
  providedIn: 'root',
})
export class ShadeService {
  /**
   * We use simple increments to notify service consumers of change. We don't
   * use changes to currentShadeToolType as a way to notify since it's possible
   * for two consecutive changes of the same type--and if a change in type were
   * how we notify, then subscribers wouldn't know there is something new to
   * render.
   */
  private incrementValue: number = 0;
  incrementer: BehaviorSubject<number>;

  currentShadeToolType: ShadeToolType = '';
  props: ShadeServiceProps = {};


  constructor() {
    this.incrementer = new BehaviorSubject<number>(this.incrementValue);
  }


  render() {
    this.incrementer.next(this.incrementValue++);
  }


  /**
   * A simple dialog box with an "OK" button
   *
   * @param header
   * @param message
   * @param closeOnButtonClick
   */
  simpleOkDialog(header: string, message: string, closeOnButtonClick: boolean = true) {
    this.startDialog(
      header,
      message,
      undefined,
      {
        closeOnButtonClick: closeOnButtonClick,
      });
  }


  infoDialog(message: string, header?: string) {
    if (!header) {
      header = 'More Info';
    }
    header = `<i class="far fa-info-circle"></i> ${header}`;
    this.simpleOkDialog(header, message);
  }


  errorDialog(
    message: string = 'Something went wrong. Please try again.',
    closeOnButtonClick: boolean = true,
  ) {
    let header = '<span class="red"><i class="far fa-diamond-exclamation"></i> Error</span>';
    this.simpleOkDialog(header, message);
  }


  unauthorizedDialog() {
    let header = '<span class="red"><i class="far fa-ban"></i> Unauthorized</span>';
    this.simpleOkDialog(header, 'You are not authorized to perform this action.');
  }


  startDialog(
    header: string,
    message: string,
    buttons: DialogBoxButton[] = [],
    options: {
      enableInput?: boolean,
      password?: boolean,
      htmlMessage?: boolean,
      closeOnButtonClick?: boolean,
    } = {}) {
    const {
      enableInput = false,
      password = false,
      htmlMessage = false,
      closeOnButtonClick = true,
    } = options;

    if (buttons.length === 0) {
      buttons.push(DIALOG_BOX_BUTTON_OK);
    }

    this.currentShadeToolType = SHADE_DIALOG_BOX;
    this.props.header = header;
    this.props.dialogOptions = buttons;
    this.props.enableInput = enableInput;
    this.props.password = password;
    this.props.closeOnButtonClick = closeOnButtonClick;

    if (htmlMessage) {
      this.props.messageHtml = message;
      this.props.message = '';
    } else {
      this.props.message = message;
      this.props.messageHtml = '';
    }

    this.render();
  }


  closeAll() {
    this.currentShadeToolType = '';
    this.render(); // probably don't even need this
  }
}
