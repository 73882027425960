import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from "../lib/page-elements/header/header.component";
import {PlatformWrapperComponent} from "../lib/page-elements/platform-wrapper/platform-wrapper.component";
import {LeftNavDrawerComponent} from "../lib/page-elements/left-nav-drawer/left-nav-drawer.component";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AppInterceptor} from "../core/interceptors/app.interceptor";
import {SessionService} from "../core/services/session.service";
import {TitleComponent} from "../lib/page-elements/title/title.component";
import {RouterModule, Routes} from "@angular/router";
import {environment} from "../environments/environment";
import {HomeComponent} from "../lib/views/home/home.component";
import {AuthedGuard} from "../core/guards/authed-guard.service";
import {FormsModule} from "@angular/forms";
import {PageTitleService} from '../core/services/page-title.service';
import {LoginComponent} from '../lib/page-elements/login/login.component';
import {NotAuthedGuard} from '../core/guards/not-authed.guard';
import {LogoutComponent} from '../lib/page-elements/logout/logout.component';
import {UserAvatarComponent} from '../lib/components/user-avatar/user-avatar.component';
import {FlexTableComponent} from '../lib/components/flex-table/flex-table.component';
import {NotificationBarComponent} from '../lib/components/notification-bar/notification-bar.component';
import {FloatingContentBoxComponent} from '../lib/components/floating-content-box/floating-content-box.component';
import {FloatingContentGridComponent} from '../lib/components/floating-content-grid/floating-content-grid.component';
import {QuickStartComponent} from '../lib/views/quick-start/quick-start.component';
import {PopoverMenuComponent} from '../lib/components/popover-menu/popover-menu.component';
import {ClickService} from '../core/services/click.service';
import {OverlayModule} from '@angular/cdk/overlay';
import {UploaderComponent} from '../lib/components/uploader/uploader.component';
import {FooterComponent} from '../lib/page-elements/footer/footer.component';
import {ContractsComponent} from '../lib/views/contracts/contracts.component';
import {ContractDetailsComponent} from '../lib/views/contract-details/contract-details.component';
import {ContractGalleryItemComponent} from '../lib/components/contract-gallery-item/contract-gallery-item.component';
import {BarItemNumberedListComponent} from '../lib/components/bar-item-numbered-list/bar-item-numbered-list.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {LoadingSpinnerComponent} from '../lib/components/loading-spinner/loading-spinner.component';
import {BarItemCheckboxComponent} from '../lib/components/bar-item-checkbox/bar-item-checkbox.component';
import {ShadeComponent} from '../lib/page-elements/shade/shade.component';
import {DialogBoxComponent} from '../lib/components/dialog-box/dialog-box.component';
import {ContractSolarResultsComponent} from '../lib/components/contract-solar-results/contract-solar-results.component';
import {CrumbsComponent} from '../lib/components/crumbs/crumbs.component';
import {ProgressBarComponent} from '../lib/components/progress-bar/progress-bar.component';
import {ContractService} from '../core/services/contract.service';
import {DataSourceService} from '../core/services/data-source.service';
import {EnvService} from '../core/services/env.service';
import {MessagingService} from '../core/services/messaging.service';
import {ShadeService} from '../core/services/shade.service';
import {UserService} from '../core/services/user.service';
import {HelpComponent} from '../lib/views/help/help.component';
import {FeatureToggleService} from '../core/services/feature-toggle.service';
import {TopicService} from '../core/services/topic.service';

const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home',
  },
  {
    path: 'login',
    canActivate: [NotAuthedGuard],
    canActivateChild: [NotAuthedGuard],
    component: LoginComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'home',
    canActivate: [AuthedGuard],
    canActivateChild: [AuthedGuard],
    component: HomeComponent,
  },
  {
    path: 'contracts',
    canActivate: [AuthedGuard],
    canActivateChild: [AuthedGuard],
    component: ContractsComponent,
  },
  {
    path: 'contracts/:contractId',
    canActivate: [AuthedGuard],
    canActivateChild: [AuthedGuard],
    component: ContractDetailsComponent,
  },
  {
    path: 'help',
    canActivate: [AuthedGuard],
    canActivateChild: [AuthedGuard],
    component: HelpComponent,
  },
];


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PlatformWrapperComponent,
    LeftNavDrawerComponent,
    TitleComponent,
    HomeComponent,
    LoginComponent,
    LogoutComponent,
    UserAvatarComponent,
    FlexTableComponent,
    NotificationBarComponent,
    FloatingContentBoxComponent,
    FloatingContentGridComponent,
    QuickStartComponent,
    PopoverMenuComponent,
    UploaderComponent,
    FooterComponent,
    ContractsComponent,
    ContractGalleryItemComponent,
    ContractDetailsComponent,
    BarItemNumberedListComponent,
    LoadingSpinnerComponent,
    BarItemCheckboxComponent,
    ShadeComponent,
    DialogBoxComponent,
    ContractSolarResultsComponent,
    CrumbsComponent,
    ProgressBarComponent,
    HelpComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: !environment.production, // <-- debugging purposes only
        scrollPositionRestoration: 'enabled',
      },
    ),
    FormsModule,
    HttpClientModule,
    OverlayModule,
    DragDropModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    ClickService,
    ContractService,
    DataSourceService,
    EnvService,
    MessagingService,
    PageTitleService,
    SessionService,
    ShadeService,
    UserService,
    FeatureToggleService,
    TopicService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
