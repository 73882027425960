import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {DataSourceService} from '../../../core/services/data-source.service';
import {BehaviorSubject, Subscription} from 'rxjs';


@Component({
  selector: 'kallo-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
})
export class UploaderComponent implements OnInit, OnDestroy {
  draggingOver: boolean = false;

  private processingSub: Subscription;

  @Input() processing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Output() files$: EventEmitter<FileList> = new EventEmitter<FileList>();
  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;


  ngOnInit() {
    this.processingSub = this.processing$.subscribe({
      next: processing => {
        this.onDragLeave();
      },
    });
  }


  ngOnDestroy() {
    this.processingSub?.unsubscribe();
  }


  onDragOver() {
    this.draggingOver = true;
  }


  onDragLeave() {
    this.draggingOver = false;
  }


  preventDefault(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }


  onDrop(event: DragEvent) {
    this.preventDefault(event);

    if (event.dataTransfer) {
      const files = event.dataTransfer.files;
      this.processFiles(files);
    }
  }


  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;

    if (input.files) {
      this.processFiles(input.files);
    }
  }


  triggerFileInput() {
    if (this.processing$.getValue()) {
      return;
    }

    this.fileInput.nativeElement.click();
  }


  processFiles(files: FileList) {
    if (this.processing$.getValue()) {
      return;
    }

    this.files$.emit(files);
  }
}
