import {Component, Input} from '@angular/core';
import {Link} from '../../models/link';


@Component({
  selector: 'kallo-crumbs',
  templateUrl: './crumbs.component.html',
  styleUrls: ['./crumbs.component.scss'],
})
export class CrumbsComponent {
  @Input() crumbs: Link[];
  @Input() color: 'blue' | 'green' = 'blue';

}
