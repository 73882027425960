<div
  cdkDropList
  class="uploader"
  [class.dragging]="draggingOver"
  [class.processing]="processing$ | async"
  (dragover)="preventDefault($event); onDragOver()"
  (dragleave)="onDragLeave()"
  (mouseout)="onDragLeave()"
  (drop)="onDrop($event)"
>
  <h2 *ngIf="!(processing$ | async)">
    Drag and drop your files here <i class="fa-regular fa-cloud-arrow-up"></i>
  </h2>
  <h2 *ngIf="processing$ | async">
    <kallo-loading-spinner></kallo-loading-spinner>
  </h2>

  <div
    *ngIf="!(processing$ | async)"
    (click)="triggerFileInput()"
    class="alt-method"
  >
    Or select files from your device
  </div>
  <div
    *ngIf="processing$ | async"
    class="alt-method processing"
  >
    Processing uploads...
  </div>

  <input
    id="uploader-input"
    type="file"
    #fileInput
    (change)="onFileSelected($event)"
    multiple
  >
</div>
