import {Component} from '@angular/core';


@Component({
  selector: 'kallo-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent {

}
