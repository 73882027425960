import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Contract, Analysis, PromptData} from '../../lib/models/contract';
import {PLATFORM_URL} from './env.service';
import {SessionService} from './session.service';
import {UUID} from '../../lib/models/uuid';


@Injectable({
  providedIn: 'root',
})
export class ContractService {
  private readonly host: string = '';


  constructor(
    private http: HttpClient,
    private session: SessionService,
  ) {
    this.host = PLATFORM_URL;
//     this.host = 'http://localhost:8080';
//     console.warn(`
// **********************************
//          Using localhost
// **********************************
// `);
//     window.alert('Using localhost');
  }


  createContract$(name: string = 'New Solar Contract', type: string = 'solar'): Observable<Contract> {
    return this.http.post<Contract>(`${this.host}/contracts`, {
      name: name,
      type: type,
    });
  }


  getContracts$(userId: UUID = this.session.user.id): Observable<Contract[]> {
    return this.http.get<Contract[]>(`${this.host}/contracts`, {
      params: {
        userId: userId,
      },
    });
  }


  createAnalysis$(contractId: UUID): Observable<Analysis> {
    return this.http.post<Analysis>(
      `${this.host}/contracts/${contractId}/analyses`,
      null,
    );
  }


  getAnalyses$(contractId: UUID): Observable<Analysis[]> {
    return this.http.get<Analysis[]>(
      `${this.host}/contracts/${contractId}/analyses`,
    );
  }


  getPromptData$(contractId: UUID, analysisId: UUID): Observable<PromptData> {
    return this.http.get<PromptData>(
      `${this.host}/contracts/${contractId}/analyses/${analysisId}/prompt-data`,
    );
  }
}
