import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {PageTitle} from "../../lib/models/page-title";
import {Link} from '../../lib/models/link';


@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  public pageTitle$: BehaviorSubject<PageTitle> = new BehaviorSubject<PageTitle>({});


  constructor() {
  }


  setPageTitle(pageTitle: PageTitle) {
    this.pageTitle$.next(pageTitle);
  }


  mergePageTitle(pageTitle: PageTitle) {
    pageTitle = {
      ...this.pageTitle$.getValue(),
      ...pageTitle,
    };

    this.setPageTitle(pageTitle);
  }


  startTitleLoader(opts: {
    title?: boolean,
    description?: boolean,
    crumbs?: boolean,
  }) {
    this.pageTitle$.next({
      title: opts.title ? '' : null,
      description: opts.description ? '' : null,
      crumbs: opts.crumbs ? [] : null,
    });
  }


  setBrowserTitle(browserTitle: string) {
    document.title = browserTitle;
  }
}
