<h3
  *ngIf="header!=''"
  [innerHTML]=header
></h3>

<div class="body">
  <div
    *ngIf="!messageHtml"
    class="message"
    [innerText]="message"
  ></div>

  <div
    *ngIf="messageHtml"
    class="message"
    [innerHtml]="messageHtml"
  ></div>

  <div *ngIf=enableInput>
    <form>
      <input
        #dialogBoxInput
        name="i1"
        autocomplete="off"
        *ngIf="!passwordInput"
        [(ngModel)]=userInput
        [type]="{'password': passwordInput}"
        (keyup.enter)="pressedEnter()"
      >
    </form>
  </div>

  <div class="buttons">
    <a
      *ngFor="let option of options; let i = index"
      [attr.href]="option.href?.url"
      [attr.target]="option.href?.target"
    >
      <button
        [className]=option.color
        [class.loading]="buttonSpinners[i]"
        [routerLink]="option.routerLink"
        (click)="execute(option.callback, i)"
      >
        <span [innerText]="option.text"></span>
        <kallo-loading-spinner></kallo-loading-spinner>
      </button>
    </a>
  </div>
</div>
