import { Component } from '@angular/core';

@Component({
  selector: 'kallo-quick-start',
  templateUrl: './quick-start.component.html',
  styleUrls: ['./quick-start.component.scss']
})
export class QuickStartComponent {

}
