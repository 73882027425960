import {Injectable} from '@angular/core';
import {UUID} from '../../lib/models/uuid';
import {BehaviorSubject, Observable} from 'rxjs';
import {DataSource, Upload} from '../../lib/models/data-source';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PLATFORM_URL} from './env.service';
import {map} from 'rxjs/operators';
import {Query} from '../../lib/models/query';

const MAX_UPLOAD_SIZE = 20000000;
const SUPPORTED_UPLOAD_MIME_TYPES: string[] = [
  "application/pdf",
  "image/gif",
  "image/jpeg",
  "image/png",
  "image/tiff",
  "image/webp",
];

export const MAX_PAGES_SOLAR_CONTRACT = 25;

export const ERROR_TOO_LARGE = Error('file too large to upload');
export const ERROR_UNSUPPORTED_MIME_TYPE = Error('file has unsupported mime type');


@Injectable({
  providedIn: 'root',
})
export class DataSourceService {
  private readonly host: string = '';

  dataSources$: BehaviorSubject<DataSource[]> = new BehaviorSubject<DataSource[]>([]);


  constructor(
    private http: HttpClient,
  ) {
    this.host = PLATFORM_URL;
//     this.host = 'http://localhost:8080';
//     console.warn(`
// **********************************
//          Using localhost
// **********************************
// `);
//     window.alert('Using localhost');
  }


  getDataSources$(query: Query): Observable<DataSource[]> {
    return new Observable<DataSource[]>(subscriber => {
      this.http.get<DataSource[]>(`${this.host}/data-sources`, {
        params: <HttpParams>query,
      }).subscribe({
        next: dss => {
          this.dataSources$.next(dss);

          subscriber.next(dss);
          subscriber.complete();
        },
        error: err => {
          subscriber.error(err);
        },
      });
    });
  }


  patchDataSource$(dataSourceId: UUID, patch: {
    name?: string,
  }): Observable<any> {
    return this.http.patch(`${this.host}/data-sources/${dataSourceId}`, patch);
  }


  deleteDataSource$(dataSourceId: UUID) {
    return this.http.delete(`${this.host}/data-sources/${dataSourceId}`);
  }


  /**
   *
   * @param file
   * @throws Error if upload would be rejected by server
   */
  validateUpload(file: File) {
    if (file.size > MAX_UPLOAD_SIZE) {
      throw ERROR_TOO_LARGE;
    }

    if (!SUPPORTED_UPLOAD_MIME_TYPES.includes(file.type)) {
      throw ERROR_UNSUPPORTED_MIME_TYPE;
    }
  }


  getUploads$(query: Query): Observable<Upload[]> {
    return new Observable<Upload[]>(subscriber => {
      this.getDataSources$(query).pipe(
        map((dss): Upload[] => {
          const uploads = dss.filter(ds => ds.type === 'upload');
          return uploads as Upload[];
        }),
      ).subscribe({
        next: uploads => {
          subscriber.next(uploads);
          subscriber.complete();
        },
        error: err => {
          subscriber.error(err);
        },
      });
    });
  }


  createUpload$(file: File, topicId: UUID): Observable<Upload> {
    return new Observable<Upload>(subscriber => {
      if (!SUPPORTED_UPLOAD_MIME_TYPES.includes(file.type)) {
        subscriber.error({
          error: 'unsupported file type',
        });

        return;
      }

      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('topicId', topicId);

      this.http.post<Upload>(`${this.host}/uploads`, formData).subscribe({
        next: upload => {
          subscriber.next(upload);
          subscriber.complete();
        },
        error: err => {
          subscriber.error();
        },
      });
    });
  }
}
