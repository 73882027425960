<a
  [routerLink]="['/contracts/', contract.id]"
  class="item"
>
  <div class="title">
    <i class="fa-solid fa-solar-panel"></i> Contract
  </div>

  <div class="name">
    {{contract.name}}
  </div>
</a>
