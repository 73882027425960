<div
  #analysisSteps
  class="steps"
>
  <div>
    <kallo-bar-item-numbered-list
      [number]="1"
      [text]="'Upload your contract'"
      [status]="uploads ? (uploads.length ? 'complete' : 'ready') : 'loading'"
    ></kallo-bar-item-numbered-list>
  </div>

  <div>
    <kallo-bar-item-numbered-list
      [number]="2"
      [text]="analysisStatusText"
      [status]="analysisStatus"
    ></kallo-bar-item-numbered-list>
  </div>

  <div>
    <kallo-bar-item-numbered-list
      [number]="3"
      [text]="'Review results'"
      [status]="analyses ? (analysisIsFinished ? 'complete' : 'ready') : 'loading'"
    ></kallo-bar-item-numbered-list>
  </div>
</div>

<ng-container
  *ngIf="analysisIsInProgress"
>
  <h2 class="gold">
    Analysis In Progress...
  </h2>

  <kallo-progress-bar
    [numerator]="analysisCompletionNumerator"
    [denominator]="analysisCompletionDenominator"
  ></kallo-progress-bar>

  <p class="gold">
    Feel free to navigate away and come back. Your progress is always saved.
  </p>
</ng-container>

<ng-container
  *ngIf="analysisResultedInError && analysisErrorMessage"
>
  <h2 class="red">
    Error
  </h2>

  <p class="red"
     [innerText]="analysisErrorMessage"
  ></p>
</ng-container>

<ng-container
  *ngIf="analysisIsFinished"
>
  <h2 class="green">
    Results
  </h2>

  <kallo-contract-solar-results
    *ngIf="promptData"
    [promptData]="promptData"
  ></kallo-contract-solar-results>
</ng-container>

<h2>
  Uploads
</h2>

<ng-container
  *ngIf="analysisCanBeStarted"
>
  <h3>
    Supported Files
  </h3>

  <div
    class="formats"
  >
    <div class="format">
      <div class="title">
        <div class="icon">
          <i class="fa-solid fa-file-pdf"></i>
        </div>

        <div class="pills">
          <div class="pill">
            .pdf
          </div>
        </div>
      </div>

      <div class="text">
        <ul>
          <li>Files cannot exceed 20MB in size</li>
          <li>PDF files may be up to {{maxPagesSolarContract}} pages long</li>
          <li>No more than {{maxPagesSolarContract}} total uploaded pages</li>
          <li>Cannot be combined with image uploads</li>
        </ul>
      </div>
    </div>

    <div class="format">
      <div class="title">
        <div class="icon">
          <i class="fa-solid fa-file-image"></i>
        </div>

        <div class="pills">
          <div class="pill">
            .gif
          </div>
          <div class="pill">
            .jpg
          </div>
          <div class="pill">
            .jpeg
          </div>
          <div class="pill">
            .png
          </div>
          <div class="pill">
            .tif
          </div>
          <div class="pill">
            .tiff
          </div>
          <div class="pill">
            .webp
          </div>
        </div>
      </div>

      <div class="text">
        <ul>
          <li>Files cannot exceed 20MB in size</li>
          <li>Each upload can only contain one page of text</li>
          <li>No more than {{maxPagesSolarContract}} uploads</li>
          <li>Cannot be combined with PDF uploads</li>
        </ul>
      </div>
    </div>
  </div>

  <h3>
    Upload Files
  </h3>

  <kallo-uploader
    [processing$]="processingUploads$"
    (files$)="processUploads($event)"
  ></kallo-uploader>
</ng-container>


<ng-container
  *ngIf="uploads?.length"
>
  <h3>
    My Uploaded Files
  </h3>

  <p
    class="info"
    *ngIf="uploads?.length > 1 && !analysisIsInProgress && !analysisIsFinished"
  >
    <i class="fa-sharp fa-regular fa-circle-info"></i> Drag and drop multiple uploads to place them in the correct order using the
    <i class="fa-solid fa-grip-dots"></i> gripper
  </p>

  <div
    cdkDropList
    [cdkDropListDisabled]="uploads.length === 1 || analysisIsInProgress || analysisIsFinished"
    (cdkDropListDropped)="processUploadOrder($event)"
    class="table"
  >
    <div
      *ngFor="let upload of uploads"
      cdkDrag
      [cdkDragData]="upload"
      class="row"
    >
      <div
        cdkDragHandle
        class="handle"
        *ngIf="!analysisIsInProgress && !analysisIsFinished"
      >
        <i class="fa-solid fa-grip-dots"></i>
      </div>

      <div
        class="name"
      >
        {{upload.name}}
      </div>

      <div
        class="trash no-drag-visibility"
        *ngIf="analysisCanBeStarted"
        (click)="removeUpload(upload.id)"
      >
        <i
          class="fa-solid fa-circle-trash"
          aria-label="Remove upload"
        ></i>
      </div>
    </div>

    <div
      *ngIf="uploads?.length === 0"
    >
      No uploads submitted yet
    </div>
  </div>
</ng-container>

<ng-container
  *ngIf="uploads?.length && analysisCanBeStarted"
>
  <h2>
    Start Analysis
  </h2>

  <p>
    Please review and approve
    <i class="fa-solid fa-check"></i> the following statements before starting the SunScreener analysis:
  </p>

  <div class="checkboxes">
    <kallo-bar-item-checkbox
      [text]="checkboxLegalText"
      [subtext]="checkboxLegalSubtext"
      [checked]="checkboxLegalChecked"
      (checked$)="checkboxLegalChecked = $event"
    ></kallo-bar-item-checkbox>

    <kallo-bar-item-checkbox
      [text]="checkboxAiText"
      [subtext]="checkboxAiSubtext"
      [checked]="checkboxAiChecked"
      (checked$)="checkboxAiChecked = $event"
    ></kallo-bar-item-checkbox>
  </div>

  <div
    #startAnalysisButton
    id="start-analysis-button"
  >
    <button
      [class.disabled]="!allCheckboxesChecked"
      (click)="startAnalysis()"
    >
      Start Analysis!
    </button>
  </div>
</ng-container>
