<div
  class="image"
>
  <img
    *ngIf="userImageSource"
    [src]="userImageSource"
    referrerpolicy="no-referrer"
  >

  <i
    *ngIf="!userImageSource"
    class="fa-solid fa-user"
  ></i>
</div>

<div
  class="name"
  *ngIf="showName"
>
  {{userFullName}}
</div>

<ng-content></ng-content>
