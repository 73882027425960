import {Component, OnDestroy, OnInit} from '@angular/core';
import {PageTitleService} from '../../../core/services/page-title.service';
import {Contract} from '../../models/contract';
import {ContractService} from '../../../core/services/contract.service';
import {SessionService} from '../../../core/services/session.service';
import {ShadeService} from '../../../core/services/shade.service';
import {Router} from '@angular/router';


@Component({
  selector: 'kallo-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss'],
})
export class ContractsComponent implements OnInit, OnDestroy {
  contracts: Contract[];


  constructor(
    private router: Router,
    private session: SessionService,
    private contractService: ContractService,
    private pageTitleService: PageTitleService,
    private shadeService: ShadeService,
  ) {
  }


  ngOnInit() {
    this.pageTitleService.startTitleLoader({
      title: true,
      description: true,
      crumbs: true,
    });

    this.session.hasAuthenticated$.subscribe({
      next: () => {
        this.getContracts();
      },
    });
  }


  getContracts() {
    this.contractService.getContracts$().subscribe({
      next: contracts => {
        this.contracts = contracts;

        this.pageTitleService.setPageTitle({
          crumbs: [{
            name: 'Contracts',
          }],
          title: `My Contracts`,
          description: contracts.length ?
            null :
            `You haven't uploaded a solar installation contract yet. Click the button below to get started!`,
          imageSrc: null,
          // buttonConfig: [{
          //   name: 'Upload a New Contract',
          //   callback: () => {
          //     this.contractService.createContract$().subscribe({
          //       next: contract => {
          //         this.router.navigate(['contracts', contract.id]);
          //       },
          //       error: err => {
          //         this.shadeService.errorDialog();
          //       },
          //     });
          //   },
          //   icon: '',
          // }],
        });
      },
    });
  }


  ngOnDestroy() {
  }


  createContract() {
    this.contractService.createContract$().subscribe({
      next: contract => {
        this.router.navigate(['contracts', contract.id]);
      },
      error: err => {
        this.shadeService.errorDialog();
      },
    });
  }
}
