import {Component, EventEmitter, Input, Output} from '@angular/core';


@Component({
  selector: 'kallo-bar-item-checkbox',
  templateUrl: './bar-item-checkbox.component.html',
  styleUrls: ['./bar-item-checkbox.component.scss'],
})
export class BarItemCheckboxComponent {
  @Input() text: string = '';
  @Input() subtext: string = '';
  @Input() checked: boolean = false;
  @Output() checked$: EventEmitter<boolean> = new EventEmitter<boolean>();


  check() {
    this.checked = true;
    this.checked$.emit(true);
  }


  uncheck() {
    this.checked = false;
    this.checked$.emit(false);
  }
}
