import {Component, OnInit} from '@angular/core';
import {SessionService} from '../../../core/services/session.service';


@Component({
  selector: 'kallo-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {

  constructor(
    private session: SessionService,
  ) {
  }


  ngOnInit() {
    this.session.isAuthenticated$.subscribe({
      next: (loggedIn) => {
        if (!loggedIn) {
          window.location.href = '/';
        }
      },
    });

    this.session.logOut();
  }
}
