import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {FirebaseOptions} from 'firebase/app';


@Injectable({
  providedIn: 'root',
})
export class EnvService {
  production: boolean = false;
  platformUrl: string = '';
  firebaseConfig: FirebaseOptions = {};


  constructor() {
    this.production = environment.production;
    this.platformUrl = environment.platformEndpoint;
    this.firebaseConfig = environment.firebaseConfig;

    PLATFORM_URL = this.platformUrl;
  }
}


export let PLATFORM_URL = '';
