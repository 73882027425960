import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {Option, OptionGroup} from '../../models/option';
import {ConnectedPosition} from '@angular/cdk/overlay';
import {Subscription} from 'rxjs';
import {ClickService} from '../../../core/services/click.service';


@Component({
  selector: 'kallo-popover-menu',
  templateUrl: './popover-menu.component.html',
  styleUrls: ['./popover-menu.component.scss'],
})
export class PopoverMenuComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() menuTitle: string;
  @Input() optionGroup: OptionGroup;
  @Input() overlayPositions: ConnectedPosition[] = [{
    originX: 'start',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'top',
  }];

  options: Option[] = [];

  @Output() clickedOption$: EventEmitter<Option> = new EventEmitter<Option>();
  @Output() clickedOutsideMenu$: EventEmitter<null> = new EventEmitter<null>();
  @Output() clickedAnywhere$: EventEmitter<Option | null> = new EventEmitter<Option | null>();

  clickSub: Subscription;

  @ViewChild('menuOverlay', {read: ElementRef}) private menuEl: ElementRef<HTMLElement>;


  constructor(
    private eRef: ElementRef,
    private clickService: ClickService,
  ) {
  }


  ngOnInit(): void {
    this.options = this.optionGroup.options;
  }


  ngAfterViewInit() {
    const preselected = this.options.find(o => o.selected);

    if (preselected) {
      const selector: string = `[data-id="${preselected.id}"]`;
      const parentEl: HTMLElement = this.menuEl.nativeElement;
      const thisEl: HTMLAnchorElement | null = parentEl.querySelector(selector);

      if (thisEl) {
        // This places the vertical center of the selected menu option
        // either at the very top of the scrollable area (i.e. making
        // the top half of the option cut off) or lower (i.e. less than
        // half of the option is cut off at the top or the option appears
        // entirely within the current viewable area of the scrollable
        // area).
        thisEl.scrollIntoView({
          block: 'center',
        });

        setTimeout(() => {
          const selectedOptionOffsetTop = thisEl.offsetTop;
          const selectedOptionHeight = thisEl.scrollHeight;
          const visibleMenuHeight = parentEl.clientHeight;
          const totalMenuHeight = parentEl.scrollHeight;

          let scrollByPx = (totalMenuHeight - selectedOptionOffsetTop) - (visibleMenuHeight / 2) - (selectedOptionHeight / 2);
          if (scrollByPx > visibleMenuHeight / 2) {
            scrollByPx = visibleMenuHeight / 2;
          }

          if (scrollByPx > 0) {
            parentEl.scrollBy({
              top: scrollByPx * -1,
            });
          }
        }, 0);
      }
    }

    this.clickSub = this.clickService.click$.subscribe(
      ($el) => {
        if (!this.eRef.nativeElement.contains($el)) {
          this.clickedOutsideMenu$.emit(null);
          this.clickedAnywhere$.emit(null);
        }
      },
    );
  }


  ngOnDestroy() {
    this.clickSub?.unsubscribe();
  }


  onClick(option: Option) {
    if (option.callback) {
      option.callback();
    }

    this.clickedOption$.emit(option);
    this.clickedAnywhere$.emit(option);
  }
}
