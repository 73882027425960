<!--<h2 class="gray">-->
<!--  A Little Bit About SunScreener...-->
<!--</h2>-->

<!--<kallo-uploader-->

<!--&gt;</kallo-uploader>-->


<div
  class="grid"
>
  <div
    *ngFor="let box of boxes"
    class="box"
    [routerLink]="box.href"
  >
    <div
      class="icon"
    >
      <i
        class="fa-fw"
        [ngClass]="box.iconClasses"
      ></i>
    </div>

    <div
      class="text"
    >
      <h2>{{box.title}}</h2>
      <p>{{box.description}}</p>
    </div>
  </div>
</div>
