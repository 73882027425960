import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {initializeApp} from 'firebase/app';
import {
  Auth,
  getAuth,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  onAuthStateChanged,
  signInWithEmailLink,
  signInWithPopup,
  User as FirebaseUser,
} from "firebase/auth";
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {User} from '../../lib/models/user';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {PLATFORM_URL} from './env.service';
import {ShadeService} from './shade.service';
import {EmailAddressNotApproved} from '../../lib/models/error/errors';

// When attempting to use a magic link, this stores the email address for which
// the magic link was generated to ensure that the magic link email is the same
// as the one we expected it to be after the user clicks that link. Prevents a
// session fixation attack.
const LocalStorageEmailLoginKey = 'firebaseEmailForLogIn';


@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private readonly host: string = '';

  user$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  firebaseUser$: BehaviorSubject<FirebaseUser | null> = new BehaviorSubject<FirebaseUser | null>(null);

  /**
   * Emits true if logged in, false if not loggged in, and null if not yet
   * determined
   */
  isAuthenticated$: BehaviorSubject<null | boolean> = new BehaviorSubject<null | boolean>(null);

  /**
   * Emits if and only if the user has authenticated. This is a way to selectively
   * load certain items only after it is known that the user is successfully logged
   * in already.
   */
  hasAuthenticated$: ReplaySubject<User> = new ReplaySubject<User>(1);

  /**
   * This is primarily to support the magic link flow. When the user clicks on a
   * magic link, there is a brief period of time when the login screen will show
   * while authentication is taking place. This gives us an opportunity to make
   * that experience better.
   */
  isAuthenticating$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);


  private readonly auth: Auth;
  private providerGoogle: GoogleAuthProvider = new GoogleAuthProvider();

  public httpNoAuth: HttpClient;


  get user(): User | null {
    return this.user$.getValue();
  }


  get firebaseUser(): FirebaseUser | null {
    return this.firebaseUser$.getValue();
  }


  get isAuthenticated(): null | boolean {
    return this.isAuthenticated$.getValue();
  }


  constructor(
    private router: Router,
    private handler: HttpBackend,
    private shade: ShadeService,
    public http: HttpClient,
  ) {
    this.host = PLATFORM_URL;
//     this.host = 'http://localhost:8080';
//     console.warn(`
// **********************************
//          Using localhost
// **********************************
// `);
//     window.alert('Using localhost');

    const app = initializeApp(environment.firebaseConfig);
    this.auth = getAuth(app);

    if (isSignInWithEmailLink(this.auth, window.location.href)) {
      this.finishLogInWithEmailLink();
    } else {
      this.isAuthenticating$.next(false);
    }

    this.setUpAuthStateListener();

    this.httpNoAuth = new HttpClient(this.handler);
  }


  private setUpAuthStateListener() {
    onAuthStateChanged(this.auth, (user) => {
      const wasAuthenticated = this.isAuthenticated;

      this.isAuthenticating$.next(true);
      this.firebaseUser$.next(user);

      if (user) {
        this.getUserByFirebaseId$(this.firebaseUser?.uid).subscribe({
          next: user => {
            this.user$.next(user);
            this.hasAuthenticated$.next(user);
            this.isAuthenticated$.next(true);
            this.isAuthenticating$.next(false);

            if (this.router.url === '/login') {
              this.navigateHome();
            }
          },
          error: err => {
            if (err?.error?.indexOf(EmailAddressNotApproved) === 0) {
              this.shade.errorDialog('SunScreener is only open to our early adopters for now.\n\nVisit SunScreener.ai to join the early adopter invite list!');
            } else {
              this.shade.errorDialog();
            }

            this.logOut();
          },
        });
      } else {
        this.isAuthenticated$.next(false);

        if (!isSignInWithEmailLink(this.auth, window.location.href)) {
          this.isAuthenticating$.next(false);
        }

        if (wasAuthenticated) {
          // We do this check in case we need to display a dialog of some kind
          // and don't want to refresh the page (thus eradicating the dialog)
          if (document.location.toString() !== '/login') {
            document.location = '/login';
          }
        }
      }
    });
  }


  private navigateHome() {
    this.router.navigate(['/home']);
  }


  private getUserByFirebaseId$(firebaseId: string): Observable<User> {
    return this.http.get<User>(`${this.host}/users`, {
      params: {
        firebaseId: firebaseId,
      },
    });
  }


  logInWithGoogle() {
    this.providerGoogle.setCustomParameters({
      prompt: 'select_account',
    });

    signInWithPopup(this.auth, this.providerGoogle).then((result) => {
      this.navigateHome();
    }).catch((error) => {
      console.log(error);
    });
  }


  logInWithEmail$(emailAddress: string): Observable<any> {
    window.localStorage.setItem(LocalStorageEmailLoginKey, emailAddress);

    return this.httpNoAuth.post(`${this.host}/magic-links`, null, {
      params: {
        email: emailAddress,
        for: 'sunscreener',
      },
    });
  }


  private finishLogInWithEmailLink() {
    if (isSignInWithEmailLink(this.auth, window.location.href)) {
      let email = window.localStorage.getItem(LocalStorageEmailLoginKey);

      if (!email) {
        // User opened the link on a different browser than the magic link
        // was requested from
        this.shade.startDialog(
          '<i class="fa-regular fa-shield-check"></i> Security Check',
          'It looks like you requested this magic link from a different browser.\n\nFor security, please verify your email address:',
          [
            {
              text: 'Verify',
              callback: (input) => {
                this._finishLogInWithEmailLink(input);
              },
              callbackIsDefault: true,
            },
            {
              text: 'Start Over',
              callback: () => {
                this.logOut();
              },
              color: 'gray',
            },
          ],
          {
            enableInput: true,
          },
        );
      } else {
        this._finishLogInWithEmailLink(email);
      }
    }
  }


  private _finishLogInWithEmailLink(email: string) {
    signInWithEmailLink(this.auth, email, window.location.href).then((result) => {
      this.navigateHome();
      window.localStorage.removeItem(LocalStorageEmailLoginKey);
    }).catch(() => {
        this.isAuthenticating$.next(false);
        this.shade.errorDialog();
      },
    );
  }


  logOut() {
    this.auth.signOut();
    this.isAuthenticating$.next(false);
  }
}
