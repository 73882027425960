<kallo-crumbs
  [crumbs]="crumbs"
  [color]="'blue'"
></kallo-crumbs>

<div
  class="crumbs"
  *ngIf="crumbs && crumbs.length === 0"
>
  <kallo-loading-spinner></kallo-loading-spinner>
</div>

<div
  class="title-area"
>
  <div
    class="left"
  >
    <h1
      *ngIf="title"
      [innerText]="title"
    ></h1>

    <h1
      *ngIf="title === ''"
    >
      <kallo-loading-spinner></kallo-loading-spinner>
    </h1>

    <p
      *ngIf="description"
      [innerText]="description"
    ></p>

    <p
      *ngIf="description === ''"
    >
      <kallo-loading-spinner></kallo-loading-spinner>
    </p>

    <div
      class="login-options"
      *ngIf="buttonConfig"
    >
      <button
        *ngFor="let button of buttonConfig; let i = index"
        [class.loading]="buttonClicked[i]"
        [routerLink]="button.route"
        (mouseup)="onButtonClick(button, i)"
      >
        <span>
          {{button.name}}
        </span>
        <span
          *ngIf="button.icon"
          [innerHtml]="'<i class=\'fa-regular '+button.icon+'\'>'"
        ></span>

        <kallo-loading-spinner></kallo-loading-spinner>
      </button>
    </div>
  </div>

  <div
    class="right"
    *ngIf="imageSrc"
  >
    <img [src]="imageSrc">
  </div>
</div>
