import {Component, Input, OnInit} from '@angular/core';
import {Contract} from '../../models/contract';
import {ContractService} from '../../../core/services/contract.service';


@Component({
  selector: 'kallo-contract-gallery-item',
  templateUrl: './contract-gallery-item.component.html',
  styleUrls: ['./contract-gallery-item.component.scss'],
})
export class ContractGalleryItemComponent implements OnInit {
  @Input() contract: Contract;


  constructor(
    private contractService: ContractService,
  ) {
  }


  ngOnInit() {

  }
}
