<script src="../../views/contract-details/contract-details.component.ts"></script>
<div id="footer">
  <div class="inner">
    <div class="social">
      Thanks for being an early adopter! <i class="fa-kit fa-sunscreener"></i>
      <!--      Share Us!-->
      <!--      <i class="fa-brands fa-facebook"></i>-->
      <!--      <i class="fa-brands fa-reddit-alien"></i>-->
      <!--      <i class="fa-brands fa-x-twitter"></i>-->
    </div>

    <div class="newsletter">
      <div class="title">
        Invite Anyone to SunScreener
      </div>

      <p>
        As an early adopter, you can invite anyone else to be an early adopter, and we'll give them immediate access. Provide us with their email address
        <i class="fa-solid fa-circle-down"></i>
      </p>

      <div class="form-area">
        <form>
          <input
            [(ngModel)]="invitationEmailAddress"
            name="email"
            placeholder="Email Address"
          >
        </form>

        <img
          id="invitationSendButton"
          #invitationSendButton
          (click)="submitInvitationForm()"
          src="assets/img/send-email.png"
        >

        <div class="form-success">
          <i class="fa-solid fa-check"></i> You're signed up!
        </div>
      </div>
    </div>

    <div class="bottom">
      <div class="info">
        <div class="left company-info">
          <img src="assets/img/sunscreener-color-black.png">

          <p>
            Through our innovative platform, users can easily upload their contracts for a comprehensive review, gaining clarity on terms, clauses, and obligations. Leveraging advanced AI technology, we provide insightful analysis, risk assessment, and clear understanding of critical terms, clauses, and obligations.
            <br><br>
            SunScreener.ai is committed to promoting transparency and understanding in the solar industry, contributing to a greener and more sustainable future. This is not legal advice.
          </p>
        </div>

        <div class="right links">
          <div class="title">
            Links
          </div>
          <br><br>
          <a [routerLink]="'/help'">Help</a>
          <br><br>
          <a target="_blank" href="https://sunscreener.ai/terms-of-use">Terms of Use</a>
          <br><br>
          <a target="_blank" href="https://sunscreener.ai/privacy-policy">Privacy Policy</a>
        </div>
      </div>

      <div class="divider"></div>

      <div class="info">
        <div class="left social-media">
<!--          <a href="https://twitter.com/KalloAI" target="_blank">-->
<!--            <i class="fa-brands fa-x-twitter"></i>-->
<!--          </a>-->
<!--          <a href="https://www.linkedin.com/company/kalloai" target="_blank">-->
<!--            <i class="fa-brands fa-linkedin-in"></i>-->
<!--          </a>-->
        </div>

        <div class="right copyright">
          © {{copyrightYear}} Kallo LLC&nbsp;<i class="fa-kit fa-kallo"></i>
        </div>
      </div>
    </div>
  </div>
</div>
