import {Component, OnDestroy, OnInit} from '@angular/core';
import {PageTitleService} from "../../../core/services/page-title.service";
import {SessionService} from '../../../core/services/session.service';
import {ContentBox} from '../../models/content-box';
import {ContractService} from '../../../core/services/contract.service';
import {Router} from '@angular/router';
import {ShadeService} from '../../../core/services/shade.service';
import {UserService} from '../../../core/services/user.service';
import {Subscription} from 'rxjs';


@Component({
  selector: 'kallo-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  boxes: ContentBox[] = [
    {
      title: 'Built by Energy Experts',
      description: 'SunScreener was built by energy industry veterans who are just as passionate about advancing the clean-energy economy as you.',
      iconClasses: [
        'fa-regular',
        'fa-wind-turbine',
      ],
      href: '',
    }, {
      title: 'Powered By AI',
      description: 'Our proprietary process uses the latest and most trusted AI-based technology to deliver you a meaningful result.',
      iconClasses: [
        'fa-regular',
        'fa-microchip-ai',
      ],
      href: '/convos',
    }, {
      title: 'Security First',
      description: 'SunScreener maintains a security-first approach, keeping you and your personal data safe. All data and connections are fully encrypted.',
      iconClasses: [
        'fa-regular',
        'fa-binary-lock',
      ],
      href: '',
    }, {
      title: 'It\'s Fast',
      description: `Upload your contract, as a PDF or by snapping pictures with your phone, and we'll get you results in just a few minutes.`,
      iconClasses: [
        'fa-regular',
        'fa-timer',
      ],
      href: '',
    },
  ];


  constructor(
    private contractService: ContractService,
    private pageTitleService: PageTitleService,
    private userService: UserService,
    private session: SessionService,
    private router: Router,
    private shade: ShadeService,
  ) {
  }


  private sub: Subscription;


  ngOnInit() {
    this.configurePageTitle();

    this.session.hasAuthenticated$.subscribe({
      next: (user) => {
        this.contractService.getContracts$(user.id).subscribe({
          next: contracts => {
            this.addActionButton(contracts.length);
          },
          error: err => {
            this.addActionButton(0);
          },
        });

        this.sub = this.session.firebaseUser$.subscribe({
          next: () => {
            this.configurePageTitle();
          },
        });
      },
    });
  }


  ngOnDestroy() {
    this.sub?.unsubscribe();
  }


  private configurePageTitle() {
    let title: string;

    if (this.session.firebaseUser?.displayName) {
      title = `Hi, ${this.session.firebaseUser?.displayName}!`;
    } else {
      title = 'Hi there!';

      this.userService.promptSetUserDisplayName$(true).subscribe({
        error: () => {
          this.shade.errorDialog();
        },
      });
    }

    this.pageTitleService.setPageTitle({
      crumbs: [{
        name: 'home',
      }],
      title: title,
      description: `SunScreener.ai is a consumer-first, AI-based solar installation contract screener, built to help consumers like you navigate the solar negotiation process.`,
      imageSrc: null,
    });
  }


  private addActionButton(userContractsQty: number) {
    if (userContractsQty === 0) {
      this.pageTitleService.mergePageTitle({
        buttonConfig: [{
          name: 'Add My First Contract',
          callback: () => {
            this.createContract();
          },
        }],
      });
    } else {
      this.pageTitleService.mergePageTitle({
        buttonConfig: [{
          route: 'contracts',
          name: 'View My Contracts',
        }, {
          name: 'Add Another Contract',
          callback: () => {
            this.createContract();
          },
        }],
      });
    }
  }


  createContract() {
    this.contractService.createContract$().subscribe({
      next: contract => {
        this.router.navigate(['contracts', contract.id]);
      },
      error: err => {
        this.shade.errorDialog();
      },
    });
  }
}
