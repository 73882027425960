<div
  #mainContent
  id="main-content"
  [class.logged-in]="loggedIn"
>
  <div
    class="content"
  >
    <kallo-header
      *ngIf="loggedIn"
    ></kallo-header>

    <kallo-title
      *ngIf="loggedIn"
    ></kallo-title>

    <router-outlet></router-outlet>

    <kallo-footer
      *ngIf="loggedIn"
    ></kallo-footer>
  </div>
</div>

<!--<kallo-left-splash *ngIf="!loggedIn"></kallo-left-splash>-->
