<div
  [attr.data-status]="status"
  [attr.data-color]="color"
  class="container"
>
  <div class="left">
    <div class="outer-circle">
      <div
        class="inner-circle"
        *ngIf="status === 'loading'"
      >
        <kallo-loading-spinner></kallo-loading-spinner>
      </div>

      <div
        class="inner-circle"
        *ngIf="status !== 'loading'"
        [innerHTML]="getNumberText()"
      ></div>
    </div>
  </div>

  <div class="right">
    {{text}}

    <div
      *ngIf="subText"
      class="subtext"
    >
      {{subText}}
    </div>
  </div>
</div>
