import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ContractService} from '../../../core/services/contract.service';
import {SessionService} from '../../../core/services/session.service';
import {PageTitleService} from '../../../core/services/page-title.service';
import {BehaviorSubject, concat, filter, forkJoin, Observable} from 'rxjs';
import {Analysis, Contract, PromptData} from '../../models/contract';
import {map} from 'rxjs/operators';
import {UUID} from '../../models/uuid';
import {ActivatedRoute} from '@angular/router';
import {Upload} from '../../models/data-source';
import {DataSourceService, MAX_PAGES_SOLAR_CONTRACT} from '../../../core/services/data-source.service';
import {ItemStatus} from '../../components/bar-item-numbered-list/bar-item-numbered-list.component';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ShadeService} from '../../../core/services/shade.service';
import {DIALOG_BOX_BUTTON_CANCEL} from '../../models/dialog-box';
import {ContractHasTooManyPages, ContractUploadNotAContract} from '../../models/error/errors';
import {TopicService} from '../../../core/services/topic.service';
import {TopicDataSource} from '../../models/topic';


@Component({
  selector: 'kallo-contract-details',
  templateUrl: './contract-details.component.html',
  styleUrls: ['./contract-details.component.scss'],
})
export class ContractDetailsComponent implements OnInit, OnDestroy {
  maxPagesSolarContract: number = MAX_PAGES_SOLAR_CONTRACT;

  contractId: UUID;
  contract: Contract;

  uploads: Upload[]; // keep this undefined until API has been queried
  analyses: Analysis[]; // keep this undefined until API has been queried

  private gettingPromptData: boolean = false;
  promptData: PromptData;

  processingUploads$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private pollTimeout: number;

  @ViewChild('startAnalysisButton') $startAnalysis!: ElementRef;
  @ViewChild('analysisSteps') $analysisSteps!: ElementRef;


  constructor(
    private contractService: ContractService,
    private dataSourceService: DataSourceService,
    private pageTitleService: PageTitleService,
    private topicService: TopicService,
    private shade: ShadeService,
    private route: ActivatedRoute,
    private session: SessionService,
  ) {
  }


  ngOnInit() {
    this.contractId = this.route.snapshot.paramMap.get('contractId');

    this.pageTitleService.startTitleLoader({
      title: true,
      description: false,
      crumbs: true,
    });

    this.session.hasAuthenticated$.subscribe({
      next: () => {
        this.getContract$().subscribe({
          next: contract => {
            this.updatePageTitle();

            this.getUploads$().subscribe({
              next: uploads => {
                this.uploads = uploads;

                if (uploads?.length > 0) {
                  this.getAnalyses$().subscribe({
                    next: analyses => {
                      this.startPollTimeout();
                    },
                    error: err => {
                      this.startPollTimeout();
                    },
                  });
                } else {
                  this.startPollTimeout();
                  this.analyses = [];
                }
              },
              error: err => {
              },
            });
          },
          error: err => {
          },
        });
      },
    });
  }


  ngOnDestroy() {
    clearTimeout(this.pollTimeout);
  }


  private updatePageTitle() {
    this.pageTitleService.setPageTitle({
      crumbs: [{
        name: 'Contracts',
        route: '/contracts',
      }, {
        name: this.contract.name,
      }],
      title: this.contract.name,
    });
  }


  private startPollTimeout() {
    if (this.pollTimeout) {
      return;
    }

    this.pollTimeout = setTimeout(() => {
      this._pollIfNeeded();
    }, 5000);
  }


  /**
   * This should ONLY be called from within startPollTimeout
   *
   * @private
   */
  private _pollIfNeeded() {
    this.pollTimeout = 0;

    if (this.analysisIsInProgress) {
      this.getAnalyses$().subscribe({
        next: analyses => {
          this.startPollTimeout();
        },
      });
    }

    this.startPollTimeout();
  }


  /**
   *
   */
  get latestAnalysis(): Analysis | null {
    if (!this.analyses || this.analyses.length === 0) {
      return null;
    }

    return this.analyses.sort((a, b): number => {
      if (a.createdAt < b.createdAt) {
        return 1;
      }

      if (a.createdAt > b.createdAt) {
        return -1;
      }

      return 0;
    })[0];
  }


  get analysisStatus(): ItemStatus {
    if (this.analysisResultedInError) {
      return 'error';
    }

    if (!this.uploads) {
      return 'loading';
    }

    if (!this.uploads.length) {
      return 'ready';
    }

    if (!this.analyses) {
      return 'loading';
    }

    if (this.latestAnalysis === null) {
      return 'ready';
    }

    if (this.analysisIsFinished) {
      return 'complete';
    }

    return 'loading';
  }


  get analysisCompletionNumerator(): number {
    const analysis = this.latestAnalysis;

    if (!analysis.statusDetails || analysis.statusDetails.length === 0) {
      return 0;
    }

    let quantityDone: number = 0;

    for (let item of analysis.statusDetails) {
      quantityDone += +item.handled;
    }

    return quantityDone;
  }


  get analysisCompletionDenominator(): number {
    const analysis = this.latestAnalysis;

    if (!analysis.statusDetails || analysis.statusDetails.length === 0) {
      return 0;
    }

    return analysis.statusDetails.length;
  }


  get analysisStatusText(): string {
    if (this.analysisResultedInError) {
      return 'Retry Sunscreener analysis';
    }

    const DEFAULT = 'Run the SunScreener analysis';

    if (!this.uploads) {
      return DEFAULT;
    }

    if (!this.uploads.length) {
      return DEFAULT;
    }

    if (!this.analyses) {
      return DEFAULT;
    }

    if (this.latestAnalysis === null) {
      return DEFAULT;
    }

    if (this.analysisIsFinished) {
      return DEFAULT;
    }

    return 'Running analysis...';
  }


  get analysisIsInProgress(): boolean {
    return this.latestAnalysis && !this.latestAnalysis.finishedAt && !this.latestAnalysis.errorAt;
  }


  get analysisIsFinished(): boolean {
    const latest = this.latestAnalysis;

    // It's possible for an analysis to have both an error and a finish time,
    // so we have to check for both here. If there's an error, we don't reveal
    // "finished" data to the end user.
    const isFinished = !!latest?.finishedAt && !latest.errorAt;

    if (isFinished) {
      this.getPromptData();
    }

    return isFinished;
  }


  /**
   * @return If there was an error, the error message to display to the end user
   */
  get analysisResultedInError(): boolean {
    return !!this.latestAnalysis?.errorAt;
  }


  get analysisErrorMessage(): string {
    // Determine if uploads have changed since the last analysis failure so that we only show the error dialog
    // if the user hasn't made any upload changes
    if (this.latestAnalysis?.dataSourceIds && this.uploads) {
      if (this.latestAnalysis?.dataSourceIds?.length === this.uploads?.length) {
        for (let upload of this.uploads) {
          if (this.latestAnalysis?.dataSourceIds?.includes(upload.id)) {
            continue;
          }

          return '';
        }
      } else {
        return '';
      }
    }

    if (!!this.latestAnalysis.errorAt) {
      switch (this.latestAnalysis.errorInfo?.error) {
        case ContractHasTooManyPages: {
          return `The analysis failed because you uploaded more than the supported maximum of ${this.maxPagesSolarContract} pages.\n\nPlease modify your uploads and then retry the analysis.`;
        }
        case ContractUploadNotAContract: {
          return `The analysis failed because it looks like your uploads aren't actually a solar contract, or at least we couldn't determine that they were.\n\nPlease modify your uploads and then retry the analysis`;
        }
        default: {
          return `This doesn't happen often, but our robots hit a snag. (It wasn't your fault.)\n\nPlease try again in a few minutes or reach out to us if it keeps happening.`;
        }
      }
    }

    return '';
  }


  get analysisCanBeStarted(): boolean {
    return this.analyses && (!this.latestAnalysis || !!this.latestAnalysis.errorAt);
  }


  private getContract$(): Observable<Contract> {
    return new Observable<Contract>(subscriber => {
      this.contractService.getContracts$().pipe(
        map(contracts => {
          for (let contract of contracts) {
            if (contract.id === this.contractId) {
              return contract;
            }
          }

          return null;
        }),
        filter(c => !!c),
      ).subscribe({
        next: contract => {
          this.contract = contract;

          subscriber.next(contract);
          subscriber.complete();
        },
        error: err => {
          subscriber.error(err);
        },
      });
    });
  }


  private getUploads$(): Observable<Upload[]> {
    return new Observable<Upload[]>(subscriber => {
      forkJoin([
        this.dataSourceService.getUploads$({topicId: this.contract.topicId}),
        this.topicService.getTopic$(this.contract.topicId),
      ]).subscribe({
        next: ([uploads, topic]) => {
          const _uploads: Upload[] = [];

          for (let dsId of topic.dataSourceIds) {
            _uploads.push(uploads.find(u => u.id === dsId));
          }

          subscriber.next(_uploads);
          subscriber.complete();
        },
        error: err => {
          subscriber.error(err);
        },
      });
    });
  }


  private getAnalyses$(): Observable<Analysis[]> {
    return new Observable<Analysis[]>(subscriber => {
      this.contractService.getAnalyses$(this.contractId).subscribe({
        next: analyses => {
          this.analyses = analyses;

          subscriber.next(analyses);
          subscriber.complete();

          if (this.analysisResultedInError) {
            const message = this.analysisErrorMessage;

            if (message) {
              this.shade.errorDialog(message);
            }
          }
        },
        error: err => {
          subscriber.error(err);
        },
      });
    });
  }


  private getPromptData() {
    if (this.gettingPromptData || this.promptData) {
      return;
    }

    this.gettingPromptData = true;

    this.contractService.getPromptData$(this.contract.id, this.latestAnalysis.id).subscribe({
      next: data => {
        this.promptData = data;
        this.contract.name = this.promptData.contractPreCheck?.nameForContract;
        this.updatePageTitle();
      },
      error: err => {
        this.gettingPromptData = false;
      },
      complete: () => {
        this.gettingPromptData = false;
      },
    });
  }


  processUploadOrder(event: CdkDragDrop<string[]>) {
    // Find the previous index of the dragged item
    const prevIndex = this.uploads.findIndex(upload => upload === event.item.data);

    // Move the item within the array for reordering
    moveItemInArray(this.uploads, prevIndex, event.currentIndex);

    this.topicService.patchTopic$(this.contract.topicId, {
      dataSourceIds: this.dataSourceIds,
    }).subscribe();
  }


  get dataSourceIds(): UUID[] {
    const dataSourceIds: UUID[] = [];

    for (let i = 0; i < this.uploads.length; i++) {
      dataSourceIds.push(this.uploads[i].id);
    }

    return dataSourceIds;
  }


  processUploads(files: FileList) {
    this.processingUploads$.next(true);
    const uploadRequests$: Observable<Upload>[] = [];

    for (let i = 0; i < files.length; i++) {
      try {
        let file = files[i];
        this.dataSourceService.validateUpload(file);
        uploadRequests$.push(this.dataSourceService.createUpload$(file, this.contract.topicId));
      } catch (e) {
        console.error(e);
      }
    }

    forkJoin(uploadRequests$).subscribe({
      next: uploads => {
        this.uploads.push(...uploads);

        // Each new upload needs to have a topic data source created for it to link the upload to the topic
        const topicDataSourceUpdates: Array<Observable<TopicDataSource>> = this.uploads.map(
          upload => {
            return this.topicService.createTopicDataSource$(this.contract.topicId, upload.id);
          },
        );

        concat(
          ...topicDataSourceUpdates,
          this.topicService.patchTopic$(this.contract.topicId, {
            dataSourceIds: this.dataSourceIds,
          }),
        ).subscribe({
          next: () => {
            // Scroll to new upload if not already in view
            setTimeout(() => {
              const elem = this.$startAnalysis.nativeElement;
              const rect = elem.getBoundingClientRect();

              if (rect.top < 0) {
                // Element is above viewport
                window.scrollBy({top: rect.top, behavior: 'smooth'});
              } else if (rect.bottom > window.innerHeight) {
                // Element is below viewport
                const overflowAmount = rect.bottom - window.innerHeight;
                // Adjust for the full height of the element
                const scrollAmount = overflowAmount + rect.height;
                window.scrollBy({top: scrollAmount, behavior: 'smooth'});
              }
            }, 0);
          },
          error: err => {
            this.shade.errorDialog();
          },
        });
      },
      error: err => {
        this.shade.errorDialog();
      },
      complete: () => {
        this.processingUploads$.next(false);
      },
    });
  }


  startAnalysis() {
    if (!this.allCheckboxesChecked) {
      console.error('tried running analysis before acceptances');
      return;
    }

    this.contractService.createAnalysis$(this.contract.id).subscribe({
      next: analysis => {
        this.analyses.push(analysis);
        this.startPollTimeout();

        setTimeout(() => {
          const elem = this.$analysisSteps.nativeElement;
          const rect = elem.getBoundingClientRect();
          const scrollPosition = window.scrollY || window.pageYOffset;
          const offset = rect.top + scrollPosition;

          window.scrollTo({
            top: offset - 10,
            behavior: 'smooth',
          });
        }, 0);
      },
      error: err => {
        console.error(err);
      },
    });
  }


  removeUpload(id: UUID) {
    this.shade.startDialog(
      'Are You Sure?',
      'If you remove this file, you will need to re-upload it to use it again.',
      [{
        text: 'Yes, Remove',
        callback: _ => {
          concat(
            this.topicService.deleteTopicDataSource$(this.contract.topicId, id),
            this.topicService.patchTopic$(this.contract.topicId, {
              dataSourceIds: this.dataSourceIds.filter(dsId => dsId !== id),
            }),
          ).subscribe({
            next: () => {
              this.uploads = this.uploads.filter(u => {
                return u.id !== id;
              });
            },
            error: () => {
              this.shade.errorDialog();
            },
          });
        },
      }, DIALOG_BOX_BUTTON_CANCEL],
    );
  }


  get allCheckboxesChecked(): boolean {
    return this.checkboxAiChecked && this.checkboxLegalChecked;
  }


  checkboxLegalChecked: boolean = false;
  checkboxLegalText: string = `Legal Disclaimer`;
  checkboxLegalSubtext: string = `<i class="fa-solid fa-arrow-left"></i> By checking this box, I acknowledge and agree that (1) SunScreener does not offer legal advice and (2) use of this tool in no way results in the creation of legal advice. I also affirm that I have read and accept the <a target="_blank" href="https://sunscreener.ai/terms-of-use">Terms of Use</a>.`;

  checkboxAiChecked: boolean = false;
  checkboxAiText: string = `AI Disclaimer`;
  checkboxAiSubtext: string = `<i class="fa-solid fa-arrow-left"></i> By checking this box, I acknowledge that the analysis results are generated by artificial intelligence (AI) and are not the opinions or viewpoints of SunScreener.`;
}
