import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {UUID} from '../../lib/models/uuid';
import {Observable} from 'rxjs';
import {PLATFORM_URL} from './env.service';
import {Topic, TopicDataSource} from '../../lib/models/topic';


@Injectable({
  providedIn: 'root',
})
export class TopicService {
  private readonly host: string;


  constructor(
    private http: HttpClient,
  ) {
    this.host = PLATFORM_URL;
//     this.host = 'http://localhost:8080';
//     console.warn(`
// **********************************
//          Using localhost
// **********************************
// `);
//     window.alert('Using localhost');
  }


  getTopic$(topicId: UUID): Observable<Topic> {
    return new Observable<Topic>(subscriber => {
      this.getTopics$({topicIds: [topicId]}).subscribe({
        next: topics => {
          if (topics.length === 1 && topics[0].id === topicId) {
            subscriber.next(topics[0]);
          } else {
            subscriber.next(null);
          }

          subscriber.complete();
        },
        error: err => {
          subscriber.error(err);
        },
      });
    });
  }


  getTopics$(query: { topicIds: UUID[] }): Observable<Topic[]> {
    const _query: {
      topicIds?: string,
    } = {
      topicIds: query.topicIds.join(','),
    };

    return this.http.get<Topic[]>(`${this.host}/topics`, {
      params: _query,
    });
  }


  patchTopic$(topicId: UUID, patch: {
    name?: string,
    description?: string,
    dataSourceIds?: UUID[],
  }): Observable<any> {
    return this.http.patch(`${this.host}/topics/${topicId}`, patch);
  }


  createTopicDataSource$(topicId: UUID, dataSourceId: UUID): Observable<TopicDataSource> {
    return this.http.post<TopicDataSource>(`${this.host}/topics/${topicId}/data-sources/${dataSourceId}`, null);
  }


  deleteTopicDataSource$(topicId: UUID, dataSourceId: UUID): Observable<any> {
    return this.http.delete(`${this.host}/topics/${topicId}/data-sources/${dataSourceId}`);
  }
}
