import {environmentConfig} from "./model";

export const environment: environmentConfig = {
  production: false,
  // platformEndpoint: 'http://localhost:8080',
  platformEndpoint: 'https://dev.platform-api.kallo.cloud',
  firebaseConfig: {
    apiKey: "AIzaSyACWaOYw7skrBq19c7aILIngqnOucojHpU",
    authDomain: "dev.kallo.app",
    projectId: "kallo-dev",
    storageBucket: "kallo-dev.appspot.com",
    messagingSenderId: "689750845410",
    appId: "1:689750845410:web:2e61e881f11c853eef7bbb",
    measurementId: "G-HYCHKTMN89",
  },
};
