import {Component, Input} from '@angular/core';

export type ItemStatus = 'loading' | 'ready' | 'error' | 'warning' | 'complete';


@Component({
  selector: 'kallo-bar-item-numbered-list',
  templateUrl: './bar-item-numbered-list.component.html',
  styleUrls: ['./bar-item-numbered-list.component.scss'],
})
export class BarItemNumberedListComponent {
  @Input() number: number = 1;
  @Input() text: string;
  @Input() subText: string;
  @Input() status: ItemStatus = 'ready';

  /**
   * Alternative inputs for more direct control...
   */
  @Input() icon: 'info' | 'magnifying-glass' | 'triangle-exclamation' | string;
  @Input() color: 'white' | 'purple' | 'gray' | 'red' | 'green' | 'gold' | 'blue' | 'lime';


  /**
   * When this.color is set to green, this returns a checkmark. If set to anything
   * else, returns a number in string format.
   */
  getNumberText(): string {
    if (this.icon) {
      return `<i class="fa-solid fa-${this.icon}"></i>`;
    }

    if (this.status === 'loading') {
      return `<kallo-loading-spinner></kallo-loading-spinner>`;
    }

    if (this.status === 'complete') {
      return `<i class="fa-solid fa-check"></i>`;
    }

    if (this.status === 'warning') {
      return `<i class="fa-solid fa-triangle-exclamation"></i>`;
    }


    if (this.status === 'error') {
      return `<i class="fa-solid fa-triangle-exclamation"></i>`;
    }

    return this.number.toLocaleString();
  }
}
