import {Component, OnInit} from '@angular/core';
import {PageTitleService} from '../../../core/services/page-title.service';


@Component({
  selector: 'kallo-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {

  constructor(
    private pageTitleService: PageTitleService,
  ) {
  }


  ngOnInit() {
    this.pageTitleService.setPageTitle({
      crumbs: [{
        name: 'Help',
      }],
      title: 'Reach Out to Us',
    });
  }
}
