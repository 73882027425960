<div
  class="login"
>
  <div>
    <div
      class="logo"
    >
      <img src="../../../assets/img/kallo-black-color-no-kallo-logo.png">
    </div>

    <div
      class="form"
    >
      <div
        class="login-options"
        [class.active]="!loggingInWithEmail && !isValidatingMagicLink"
      >
        <button
          (click)="logInWithGoogle()"
        >
          <i class="fa-brands fa-google"></i> Log In with Google
        </button>
        <button
          (click)="loggingInWithEmail = true"
        >
          <i class="fa-regular fa-envelope"></i> Log In with Email
        </button>
      </div>

      <div
        class="input"
        *ngIf="loggingInWithEmail"
        [class.active]="loggingInWithEmail"
      >
        <ng-container
          *ngIf="isValidatingMagicLink"
        >
          <div class="validating">
            <kallo-loading-spinner></kallo-loading-spinner>
          </div>
        </ng-container>

        <ng-container
          *ngIf="!isValidatingMagicLink"
        >
          <input
            id="emailInput"
            [(ngModel)]="emailInput"
            placeholder="Email Address"
            [disabled]="submittingEmail || submittedEmail"
          >

          <div class="buttons">
            <ng-container
              *ngIf="!submittingEmail && !submittedEmail"
            >
              <button
                (click)="loggingInWithEmail = false"
              >
                Cancel
              </button>

              <button
                (click)="logInWithEmail()"
              >
                Send Me a Magic Link
              </button>
            </ng-container>

            <ng-container
              *ngIf="submittingEmail"
            >
              <kallo-loading-spinner></kallo-loading-spinner>
            </ng-container>

            <ng-container
              *ngIf="submittedEmail"
            >
              Check your email for a magic link!
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="divider"></div>

    <div
      class="info"
    >
      <ng-container *ngIf="toggleInviteOnly === true">
        SunScreener is currently invite-only.
        <br>
        Request an invite at <a href="https://sunscreener.ai">SunScreener.ai</a>
        <br><br>
      </ng-container>

      <ng-container *ngIf="toggleInviteOnly === undefined">
        <kallo-loading-spinner></kallo-loading-spinner>
        <br><br>
      </ng-container>

      <strong>© {{copyrightYears}} SunScreener.ai</strong>
      <br>
      <a target="_blank" href="https://sunscreener.ai/privacy-policy">
        Privacy Policy
      </a>
      <br>
      <a target="_blank" href="https://sunscreener.ai/terms-of-use">
        Terms of Use
      </a>
    </div>

  </div>
</div>
