<div
  class="dummy-bkgd"
></div>

<kallo-platform-wrapper
  *ngIf="(loggedIn$ | async) !== null"
  [loggedIn]="loggedIn$ | async"
></kallo-platform-wrapper>

<kallo-shade></kallo-shade>
