import { Component } from '@angular/core';

@Component({
  selector: 'kallo-floating-content-grid',
  templateUrl: './floating-content-grid.component.html',
  styleUrls: ['./floating-content-grid.component.scss']
})
export class FloatingContentGridComponent {

}
