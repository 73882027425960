<div
  class="origin"
  cdkOverlayOrigin
  #menuOrigin="cdkOverlayOrigin"
></div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="menuOrigin"
  [cdkConnectedOverlayOpen]="true"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayViewportMargin]="20"
  [cdkConnectedOverlayPositions]="overlayPositions"
>
  <div
    #menuOverlay
    class="menu"
  >
    <div
      *ngIf="menuTitle"
      class="menu-item title"
    >
      {{menuTitle}}
    </div>

    <a
      *ngFor="let option of options"
      [attr.data-id]="option.id"
      [routerLink]="option.href ?? null"
      [ngClass]="{
        'menu-item': true,
        'selected': option.selected,
      }"
      (click)=onClick(option)
    >
      <div
        class="text"
      >
        {{option.name}}
      </div>
    </a>
  </div>
</ng-template>
