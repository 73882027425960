<div
  class="top"
>
  <div>
    <div
      class="left"
    >
      <img src="../../../assets/img/sunscreener-color-black.png">
    </div>

    <div
      class="middle"
    >
      <div
        class="icons"
      >
        <a
          [routerLink]="'home'"
          [routerLinkActive]="'active'"
        >
          <i class="fa-regular fa-home"></i>
        </a>
        <a
          [routerLink]="'contracts'"
          [routerLinkActive]="'active'"
        >
          <i class="fa-regular fa-file-contract"></i>
          <span class="text">
            Contracts
          </span>
        </a>
<!--        <a-->
<!--          [routerLink]="'settings'"-->
<!--          [routerLinkActive]="'active'"-->
<!--        >-->
<!--          <i class="fa-regular fa-gear"></i>-->
<!--          <span class="text">-->
<!--            Settings-->
<!--          </span>-->
<!--        </a>-->
        <a
          [routerLink]="'help'"
          [routerLinkActive]="'active'"
        >
          <i class="fa-regular fa-circle-question"></i>
          <span class="text">
            Help
          </span>
        </a>
      </div>

      <!--      <div-->
      <!--        class="text-input"-->
      <!--        (mouseup)="activateChat()"-->
      <!--      >-->
      <!--        Ask Kallo anything-->
      <!--        <i class="fa-kit fa-kallo"></i>-->
      <!--      </div>-->
    </div>

    <a
      class="right"
      (click)="activateUserMenu()"
    >
      <kallo-user-avatar>
        <kallo-popover-menu
          *ngIf="userMenuOptions.menuActive"
          [menuTitle]="session.firebaseUser?.displayName ?? ''"
          [optionGroup]="userMenuOptions"
          (clickedAnywhere$)="deactivateUserMenu()"
        ></kallo-popover-menu>
      </kallo-user-avatar>
    </a>
  </div>
</div>

<!--<div-->
<!--  class="nav"-->
<!--&gt;-->
<!--  <div>-->
<!--    <div-->
<!--      class="item on"-->
<!--    >-->
<!--      Dashboard-->
<!--    </div>-->
<!--    <div-->
<!--      class="item"-->
<!--    >-->
<!--      Agent Advisor-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<div
  class="crumbs"
>

</div>
