<div
  class="left-right-introduction section"
>
  <div
    class="left"
  >

    <h3>
      Here are the big items we<br>look out for in our analysis

      <div class="dynamic-arrow">
        <i class="fa-regular fa-circle-arrow-right"></i>
      </div>
    </h3>
  </div>

  <div
    class="right"
  >
    <div
      class="icon-text-list"
      *ngFor="let item of introItems"
    >
      <div class="icon">
        <i
          [ngClass]="['fa-regular', 'fa-fw', item.icon]"
        ></i>
      </div>

      <div
        class="text"
        [innerText]="item.name"
      ></div>
    </div>
  </div>
</div>

<div class="section">
  <kallo-bar-item-numbered-list
    [status]="'error'"
    [text]="'Legal Disclaimer'"
    [subText]="legalDisclaimer"
    class="full-width"
  ></kallo-bar-item-numbered-list>

  <kallo-bar-item-numbered-list
    [color]="'gold'"
    [icon]="'magnifying-glass'"
    [text]="'AI Disclaimer'"
    [subText]="aiDisclaimer"
    class="full-width"
  ></kallo-bar-item-numbered-list>
</div>

<div class="divider section"></div>

<div class="section">
  <kallo-crumbs
    [crumbs]="crumbs.contractBasics"
    [color]="'green'"
  ></kallo-crumbs>

  <div
    class="left-right-explanation"
  >
    <div
      class="left"
    >
      <h2>
        Transaction Type

        <div class="dynamic-arrow">
          <i class="fa-regular fa-circle-arrow-right"></i>
        </div>
      </h2>
    </div>

    <div
      class="right"
      [ngSwitch]="transactionType"
    >
      <ng-container
        *ngSwitchCase="'lease'"
      >
        <h3>
          <div class="icon">
            <i class="fa-regular fa-file-contract"></i>
          </div>

          Lease
        </h3>

        <p>
          Your contract appears to be a lease.
        </p>

        <p class="smaller">
          A solar panel lease agreement is a contractual arrangement where a homeowner or business agrees to have solar panels installed on their property by a third-party provider for a monthly cost. In return, the homeowner or business leases the panels for a set period, typically 20-25 years, paying a monthly fee to the provider. This fee is often offset by the reduction in monthly electricity bills due to the generated solar energy. Throughout the lease term, the solar company typically remains responsible for the maintenance and performance of the panels, and at the end of the term, the homeowner or business may have the option to purchase the panels, renew the lease, or have the panels removed.
        </p>
      </ng-container>

      <ng-container
        *ngSwitchCase="'purchase'"
      >
        <h3>
          <div class="icon">
            <i class="fa-regular fa-file-contract"></i>
          </div>

          Purchase
        </h3>

        <p>
          Your contract appears to be a purchase.
        </p>

        <p class="smaller">
          A purchase of a solar panel system involves a homeowner or business paying the full cost of the system and its installation outright, becoming the sole owner of the system. This direct purchase allows the property owner to immediately benefit from any federal, state, or local tax incentives, rebates, and grants. Over time, the electricity generated by the solar panels can offset or eliminate monthly utility bills, allowing the property owner to recoup the initial investment. While the upfront costs can be significant, this method often results in the highest long-term savings and increases the property's value. As the system's owner, the homeowner or business is responsible for maintenance and repairs, but with no ongoing monthly payments or obligations to a third party, they reap all the financial benefits of the system's energy production.
        </p>
      </ng-container>

      <ng-container
        *ngSwitchCase="'ppa'"
      >
        <h3>
          <div class="icon">
            <i class="fa-regular fa-file-contract"></i>
          </div>

          Power Purchase Agreement
        </h3>

        <p>
          Your contract appears to be a Power Purchase Agreement.
        </p>

        <p class="smaller">
          A Power Purchase Agreement (PPA) is a financial arrangement in which a third-party developer designs, finances, installs, and maintains a solar energy system on a homeowner's or business's property at little to no cost. Instead of paying for the solar panel system itself, the property owner buys the electricity it produces, typically at a rate lower than the local utility's retail rate. This contractually agreed-upon rate is often fixed or escalates at a predictable rate for a set period, commonly 15-25 years. The developer reaps the financial benefits, including tax credits and income from the sale of electricity, while the property owner enjoys reduced electric bills. At the end of the PPA contract term, the property owner can often renew the agreement, purchase the system, or opt to have it removed.
        </p>
      </ng-container>

      <ng-container
        *ngSwitchCase="'solar-loan'"
      >
        <h3>
          <div class="icon">
            <i class="fa-regular fa-file-contract"></i>
          </div>

          Solar Loan
        </h3>

        <p>
          Your contract appears to be a solar loan.
        </p>

        <p class="smaller">
          Solar Loans are specialized financing options tailored for the acquisition and installation of solar panel systems. Instead of paying the entire cost of the system upfront, homeowners can borrow the necessary funds and pay them back over an agreed-upon period, typically with interest. This enables homeowners to own the solar system from the start, much like an up-front purchase, but with the flexibility of spreading the costs over several years. As they own the system, homeowners can directly benefit from any federal, state, or local tax incentives and rebates. Over time, the energy savings from the solar panels can significantly offset monthly loan payments. The terms, interest rates, and specifics of solar loans can vary based on the lending institution, and while they entail taking on debt, they provide a pathway for homeowners to harness solar energy and its financial benefits without a large initial capital outlay.
        </p>
      </ng-container>

      <ng-container
        *ngSwitchDefault
      >
        <h3>
          <div class="icon">
            <i class="fa-regular fa-file-contract"></i>
          </div>

          Unknown Contract Type
        </h3>

        <kallo-bar-item-numbered-list
          [status]="'error'"
          [subText]="'We could not determine your contract type.'"
        ></kallo-bar-item-numbered-list>
      </ng-container>
    </div>
  </div>
</div>

<div
  *ngIf="promptData.transactionType?.stipulations?.length"
  class="horizontal-data-points"
>
  <div
    class="points"
  >
    <kallo-bar-item-numbered-list
      *ngFor="let text of promptData.transactionType.stipulations; let i = index"
      [subText]="text"
      [number]="i + 1"
    ></kallo-bar-item-numbered-list>
  </div>
</div>


<div class="divider"></div>


<div class="section">
  <kallo-crumbs
    [crumbs]="crumbs.costBreakdown"
    [color]="'green'"
  ></kallo-crumbs>

  <div
    class="left-right-explanation"
  >
    <div
      class="left"
    >
      <h2>
        Cost Breakdown

        <div class="dynamic-arrow">
          <i class="fa-regular fa-circle-arrow-right"></i>
        </div>
      </h2>
    </div>

    <div
      class="right green"
    >
      <h3>
        <div class="icon">
          <i class="fa-regular fa-receipt"></i>
        </div>

        Contract Costs
      </h3>

      <div
        class="text-and-crumb"
      >
        Deposit or Down Payment

        <div class="pill">
          ${{(+promptData.paymentDetails?.depositDollars).toLocaleString()}}
        </div>
      </div>

      <p
        *ngIf="transactionType === 'purchase'"
        class="smaller"
      >
        As a purchase, it is typical to see a deposit required up-front to cover a sizable portion of the equipment and materials the installer will purchase.
      </p>

      <p
        *ngIf="transactionType !== 'purchase'"
        class="smaller"
      >
        <ng-container
          *ngIf="!promptData.paymentDetails?.depositDollars"
        >
          Since you are not making a straight purchase of the system, we are not surprised to find no required deposit.
        </ng-container>

        <ng-container
          *ngIf="promptData.paymentDetails?.depositDollars"
        >
          Since you are not making a straight purchase of the system, we expect to see very little to no money down required, but it can vary based on your project's specifications.
        </ng-container>
      </p>


      <ng-container
        *ngIf="transactionType === 'purchase'"
      >
        <div
          class="text-and-crumb"
        >
          Total Cost

          <div
            class="pill"
            *ngIf="promptData.paymentDetails?.totalCost"
          >
            ${{(+promptData.paymentDetails?.totalCost).toLocaleString()}}
          </div>
        </div>

        <p
          class="smaller"
          *ngIf="promptData.paymentDetails?.totalCost"
        >
          This is the amount you should have paid by the time the installation is complete.
        </p>

        <p
          class="smaller"
          *ngIf="!promptData.paymentDetails?.totalCost"
        >
          We were not able to determine the total cost.
        </p>
      </ng-container>


      <ng-container
        *ngIf="promptData.paymentDetails?.monthlyPaymentDollars"
      >
        <div
          class="text-and-crumb"
        >
          Monthly Payment

          <div class="pill">
            ${{(+promptData.paymentDetails?.monthlyPaymentDollars).toLocaleString()}}
          </div>
        </div>

        <p
          class="smaller"
        >
          <ng-container
            *ngIf="transactionType === 'solar-loan'"
          >
            This is the monthly amount you will pay for having financed the purchase of your solar system. Keep in mind that if your electricity usage exceeds the amount generated by your system, you may also have a utility bill to pay.
          </ng-container>

          <ng-container
            *ngIf="transactionType !== 'solar-loan'"
          >
            This is the monthly amount you will pay as a base charge, which does not include per-kWh charges.
          </ng-container>
        </p>
      </ng-container>


      <ng-container
        *ngIf="promptData.paymentDetails?.monthlyPaymentDollars"
      >
        <div
          class="text-and-crumb"

        >
          Annual Increase

          <div class="pill">
            {{(+promptData.paymentDetails?.annualIncreasePercentage)}}%
          </div>
        </div>

        <p
          class="smaller"
        >
          <ng-container
            *ngIf="promptData.paymentDetails?.annualIncreasePercentage"
          >
            This is the percentage by which your monthly payment will increase every year.
          </ng-container>

          <ng-container
            *ngIf="!promptData.paymentDetails?.annualIncreasePercentage"
          >
            Our AI did not see mention of an annual increase, but we always encourage having a discussion with the provider to be sure.
          </ng-container>
        </p>
      </ng-container>


      <ng-container
        *ngIf="transactionType === 'solar-loan'"
      >
        <div
          class="text-and-crumb"

        >
          Interest Rate

          <div class="pill">
            {{(+promptData.paymentDetails?.interest?.rate)}}%
          </div>
        </div>

        <p
          class="smaller"
        >
          This is the interest rate for your loan.

          <ng-container
            *ngIf="promptData.paymentDetails?.interest?.isFixed === null"
          >
            Our AI did not find information telling us whether it is a fixed rate or not.
          </ng-container>
          <ng-container
            *ngIf="promptData.paymentDetails?.interest?.isFixed === false"
          >
            It is a fixed rate, so you do not need to worry about fluctuations.
          </ng-container>
          <ng-container
            *ngIf="promptData.paymentDetails?.interest?.isFixed === true"
          >
            Because the rate is variable, you should be aware that your monthly finance charges can change with time.
          </ng-container>
        </p>
      </ng-container>
    </div>
  </div>

  <div
    class="left-right-explanation"
    *ngIf="paymentSchedule.length"
  >
    <div
      class="left"
    ></div>

    <div
      class="right green"
    >
      <h3>
        <div class="icon">
          <i class="fa-regular fa-calendar-days"></i>
        </div>

        Payment Schedule
      </h3>

      <ng-container
        *ngFor="let payment of paymentSchedule"
      >
        <div
          class="text-and-crumb"

        >
          {{payment.purpose}}

          <div
            class="pill"
            *ngIf="payment.dollarAmount"
          >
            ${{(+payment.dollarAmount).toLocaleString()}}
          </div>
        </div>

        <p
          class="smaller"
        >
          {{payment.isDueWhen}}
        </p>
      </ng-container>
    </div>
  </div>

  <div
    class="left-right-explanation"
    *ngIf="promptData.electricityCosts?.initialCostPerKwh"
  >
    <div
      class="left"
    ></div>

    <div
      class="right green"
    >
      <h3>
        <div class="icon">
          <i class="fa-regular fa-lightbulb"></i>
        </div>

        Electricity Costs
      </h3>

      <div
        class="text-and-crumb"

      >
        Initial Cost per kWh

        <div
          class="pill"
        >
          ${{(+promptData.electricityCosts?.initialCostPerKwh)}}
        </div>
      </div>

      <kallo-bar-item-numbered-list
        *ngIf="promptData.electricityCosts?.initialCostPerKwh"
        [color]="'gold'"
        [subText]="'This is how much you will pay for actual electricity usage. This rate is paid in addition to the costs listed above.'"
        [icon]="'file-invoice-dollar'"
      ></kallo-bar-item-numbered-list>

      <ng-container
        *ngIf="promptData.electricityCosts.contractDefinesCostPerKwhChanges && promptData.electricityCosts?.howCostPerKwhChanges"
      >
        <kallo-bar-item-numbered-list
          [color]="'gold'"
          [subText]="promptData.electricityCosts?.howCostPerKwhChanges"
          [icon]="'money-bill-trend-up'"
        ></kallo-bar-item-numbered-list>
      </ng-container>

      <ng-container
        *ngIf="!promptData.electricityCosts.contractDefinesCostPerKwhChanges"
      >
        <kallo-bar-item-numbered-list
          [color]="'white'"
          [subText]="'Our AI did not find any, but make sure you review your contract closely to find any applicable annual increases to your per-kWh rate. These increases are typical.'"
          [icon]="'money-bill-trend-up'"
        ></kallo-bar-item-numbered-list>
      </ng-container>
    </div>
  </div>
</div>


<div
  *ngIf="otherCostsInfo?.length"
  class="section horizontal-data-points"
>
  <div
    class="points"
  >
    <kallo-bar-item-numbered-list
      *ngFor="let text of otherCostsInfo; let i = index"
      [subText]="text"
      [color]="'green'"
      [number]="i + 1"
    ></kallo-bar-item-numbered-list>
  </div>
</div>


<div class="divider"></div>


<div class="section">
  <kallo-crumbs
    [crumbs]="crumbs.warranties"
    [color]="'green'"
  ></kallo-crumbs>

  <h2>
    Warranty Information <span class="icon blue"><i class="fa-regular fa-circle-arrow-down"></i></span>
  </h2>

  <div
    class="left-right-explanation"
  >
    <div
      class="right purple"
    >
      <h3>
        <div class="icon">
          <i class="fa-regular fa-screwdriver-wrench"></i>
        </div>

        Standard Warranties
      </h3>

      <ng-container>
        <div
          class="text-and-crumb"
        >
          Workmanship

          <div
            class="pill purple"
            *ngIf="promptData.warranties?.workmanshipWarranty?.years"
          >
            {{promptData.warranties?.workmanshipWarranty?.years}} years
          </div>

          <div
            class="pill red"
            *ngIf="!promptData.warranties?.workmanshipWarranty?.years"
          >
            Unknown
          </div>
        </div>

        <ng-container
          *ngIf="promptData.warranties?.workmanshipWarranty?.itemsCovered?.length"
        >
          <p
            class="smaller"
          >
            Here's what we found is covered:
          </p>

          <ul>
            <li
              *ngFor="let item of promptData.warranties?.workmanshipWarranty?.itemsCovered"
              [innerText]="item"
            ></li>
          </ul>
        </ng-container>

        <ng-container
          *ngIf="!promptData.warranties?.workmanshipWarranty?.itemsCovered"
        >
          <ng-container
            *ngIf="!promptData.warranties?.workmanshipWarranty?.years"
          >
            <kallo-bar-item-numbered-list
              [status]="'warning'"
              [subText]="'We were not able to identify specific items covered by workmanship warranty coverage.'"
            ></kallo-bar-item-numbered-list>
          </ng-container>

          <ng-container
            *ngIf="!promptData.warranties?.workmanshipWarranty?.years"
          >
            <kallo-bar-item-numbered-list
              [status]="'error'"
              [subText]="'We were not able to identify workmanship warranty coverage. Be sure workmanship is warranted before moving forward.'"
            ></kallo-bar-item-numbered-list>
          </ng-container>
        </ng-container>

        <ng-container
          *ngIf="promptData.warranties?.workmanshipWarranty?.stipulations?.length"
        >
          <p
            class="smaller"
          >
            Additional notes:
          </p>

          <ul>
            <li
              *ngFor="let item of promptData.warranties?.workmanshipWarranty?.stipulations"
              [innerText]="item"
            ></li>
          </ul>
        </ng-container>
      </ng-container>


      <ng-container>
        <div
          class="text-and-crumb"
        >
          Roof Penetration

          <div
            class="pill purple"
            *ngIf="promptData.warranties?.roofPenetrationWarranty?.years"
          >
            {{promptData.warranties?.roofPenetrationWarranty?.years}} years
          </div>

          <div
            class="pill red"
            *ngIf="!promptData.warranties?.roofPenetrationWarranty?.years"
          >
            Unknown
          </div>
        </div>

        <ng-container
          *ngIf="promptData.warranties?.roofPenetrationWarranty?.itemsCovered?.length"
        >
          <p
            class="smaller"
          >
            Here's what we found is covered:
          </p>

          <ul>
            <li
              *ngFor="let item of promptData.warranties?.roofPenetrationWarranty?.itemsCovered"
              [innerText]="item"
            ></li>
          </ul>
        </ng-container>

        <ng-container
          *ngIf="!promptData.warranties?.roofPenetrationWarranty?.itemsCovered?.length"
        >
          <ng-container
            *ngIf="promptData.warranties?.roofPenetrationWarranty?.years"
          >
            <kallo-bar-item-numbered-list
              [status]="'warning'"
              [subText]="'Our AI did not find items specifically covered by roof penetration warranty coverage.'"
            ></kallo-bar-item-numbered-list>
          </ng-container>

          <ng-container
            *ngIf="!promptData.warranties?.roofPenetrationWarranty?.years"
          >
            <kallo-bar-item-numbered-list
              [status]="'error'"
              [subText]="'Our AI did not find roof penetration warranty coverage.'"
            ></kallo-bar-item-numbered-list>
          </ng-container>
        </ng-container>

        <ng-container
          *ngIf="promptData.warranties?.roofPenetrationWarranty?.stipulations?.length"
        >
          <p
            class="smaller"
          >
            Additional notes:
          </p>

          <ul>
            <li
              *ngFor="let item of promptData.warranties?.roofPenetrationWarranty?.stipulations"
              [innerText]="item"
            ></li>
          </ul>
        </ng-container>
      </ng-container>


      <ng-container
        *ngIf="!specificEquipmentWarranties?.length"
      >
        <div
          class="text-and-crumb"
        >
          Equipment

          <div
            class="pill purple"
            *ngIf="promptData.warranties?.equipmentWarranty?.years"
          >
            {{promptData.warranties?.equipmentWarranty?.years}} years
          </div>

          <div
            class="pill red"
            *ngIf="!promptData.warranties?.equipmentWarranty?.years"
          >
            Unknown
          </div>
        </div>

        <ng-container
          *ngIf="promptData.warranties?.equipmentWarranty?.itemsCovered?.length"
        >
          <p
            class="smaller"
          >
            Here's what we found is covered:
          </p>

          <ul>
            <li
              *ngFor="let item of promptData.warranties?.equipmentWarranty?.itemsCovered"
              [innerText]="item"
            ></li>
          </ul>
        </ng-container>

        <ng-container
          *ngIf="!promptData.warranties?.equipmentWarranty?.itemsCovered?.length"
        >
          <ng-container
            *ngIf="!promptData.warranties?.equipmentWarranty?.years"
          >
            <kallo-bar-item-numbered-list
              [status]="'warning'"
              [subText]="'Our AI did not find items specifically covered by equipment warranty coverage.'"
            ></kallo-bar-item-numbered-list>
          </ng-container>

          <ng-container
            *ngIf="!promptData.warranties?.equipmentWarranty?.years"
          >
            <kallo-bar-item-numbered-list
              [status]="'error'"
              [subText]="'Our AI did not find equipment warranty coverage.'"
            ></kallo-bar-item-numbered-list>
          </ng-container>
        </ng-container>

        <ng-container
          *ngIf="promptData.warranties?.equipmentWarranty?.stipulations?.length"
        >
          <p
            class="smaller"
          >
            Additional notes:
          </p>

          <ul>
            <li
              *ngFor="let item of promptData.warranties?.equipmentWarranty?.stipulations"
              [innerText]="item"
            ></li>
          </ul>
        </ng-container>
      </ng-container>


      <ng-container
        *ngIf="promptData.warranties?.solarPanelWarranty?.years || !generalEquipmentWarranty"
      >
        <div
          class="text-and-crumb"
        >
          Solar Panel Equipment

          <div
            class="pill purple"
            *ngIf="promptData.warranties?.solarPanelWarranty?.years"
          >
            {{promptData.warranties?.solarPanelWarranty?.years}} years
          </div>

          <div
            class="pill red"
            *ngIf="!promptData.warranties?.solarPanelWarranty?.years"
          >
            Unknown
          </div>
        </div>

        <ng-container
          *ngIf="promptData.warranties?.solarPanelWarranty?.stipulations?.length"
        >
          <p
            class="smaller"
          >
            Additional notes:
          </p>

          <ul>
            <li
              *ngFor="let item of promptData.warranties?.solarPanelWarranty?.stipulations"
              [innerText]="item"
            ></li>
          </ul>
        </ng-container>
      </ng-container>


      <ng-container
        *ngIf="promptData.warranties?.inverterWarranty?.years || !generalEquipmentWarranty"
      >
        <div
          class="text-and-crumb"
        >
          Inverter Equipment

          <div
            class="pill purple"
            *ngIf="promptData.warranties?.inverterWarranty?.years"
          >
            {{promptData.warranties?.inverterWarranty?.years}} years
          </div>

          <div
            class="pill red"
            *ngIf="!promptData.warranties?.inverterWarranty?.years"
          >
            Unknown
          </div>
        </div>

        <ng-container
          *ngIf="promptData.warranties?.inverterWarranty?.stipulations?.length"
        >
          <p
            class="smaller"
          >
            Additional notes:
          </p>

          <ul>
            <li
              *ngFor="let item of promptData.warranties?.inverterWarranty?.stipulations"
              [innerText]="item"
            ></li>
          </ul>
        </ng-container>
      </ng-container>


      <ng-container
        *ngIf="promptData.warranties?.batteryWarranty?.years || !generalEquipmentWarranty"
      >
        <div
          class="text-and-crumb"
        >
          Battery Equipment

          <div
            class="pill purple"
            *ngIf="promptData.warranties?.batteryWarranty?.years"
          >
            {{promptData.warranties?.batteryWarranty?.years}} years
          </div>

          <div
            class="pill red"
            *ngIf="!promptData.warranties?.batteryWarranty?.years"
          >
            Unknown
          </div>
        </div>

        <ng-container
          *ngIf="promptData.warranties?.batteryWarranty?.stipulations?.length"
        >
          <p
            class="smaller"
          >
            Additional notes:
          </p>

          <ul>
            <li
              *ngFor="let item of promptData.warranties?.batteryWarranty?.stipulations"
              [innerText]="item"
            ></li>
          </ul>
        </ng-container>
      </ng-container>


    </div>

    <div
      class="right red"
    >
      <h3>
        <div class="icon">
          <i class="fa-regular fa-hexagon-exclamation"></i>
        </div>

        Warranty Exclusions
      </h3>

      <ng-container
        *ngIf="!promptData.warrantyExclusions?.warrantyExclusionsAndLimitations?.length"
      >
        <kallo-bar-item-numbered-list
          [status]="'warning'"
          [subText]="'Our AI did not find any warranty exclusions or limitations. Speak to your installer to find out if we missed anything, or whether there are limitations that simply aren\'t present in the contract.'"
        ></kallo-bar-item-numbered-list>
      </ng-container>

      <ng-container
        *ngIf="promptData.warrantyExclusions?.warrantyExclusionsAndLimitations?.length"
      >
        <p>
          We found these exclusions/limitations present in the contract:
        </p>

        <ul>
          <li
            *ngFor="let item of promptData.warrantyExclusions?.warrantyExclusionsAndLimitations"
            [innerText]="item"
          ></li>
        </ul>

        <kallo-bar-item-numbered-list
          [status]="'warning'"
          [subText]="'Be sure to carefully read and understand exclusions and limitations to your warranty!'"
        ></kallo-bar-item-numbered-list>
      </ng-container>
    </div>
  </div>
</div>


<div class="divider"></div>


<div class="section">
  <kallo-crumbs
    [crumbs]="crumbs.equipmentSpecifications"
    [color]="'green'"
  ></kallo-crumbs>

  <div
    class="left-right-explanation"
  >
    <div
      class="left"
    >
      <h2>
        Equipment + Output

        <div class="dynamic-arrow">
          <i class="fa-regular fa-circle-arrow-right"></i>
        </div>
      </h2>
    </div>

    <div
      class="right gold"
    >
      <h3>
        <div class="icon">
          <i class="fa-sharp fa-regular fa-solar-panel"></i>
        </div>

        Equipment List
      </h3>

      <div
        class="text-and-crumb"
      >
        Solar Panels

        <div
          class="pill gold"
          *ngIf="promptData.equipmentDetails?.solarPanels?.quantity"
        >
          {{(+promptData.equipmentDetails?.solarPanels?.quantity).toLocaleString()}} panels
        </div>

        <div
          class="pill red"
          *ngIf="!promptData.equipmentDetails?.solarPanels?.quantity"
        >
          Unknown
        </div>
      </div>

      <ng-container
        *ngIf="promptData.equipmentDetails?.solarPanels?.specifications?.length"
      >
        <ul>
          <li
            *ngFor="let item of promptData.equipmentDetails?.solarPanels?.specifications"
            [innerText]="item"
          ></li>
        </ul>
      </ng-container>

      <div
        class="text-and-crumb"
      >
        Inverters

        <div
          class="pill gold"
          *ngIf="promptData.equipmentDetails?.inverters?.quantity"
        >
          {{(+promptData.equipmentDetails?.inverters?.quantity).toLocaleString()}} inverters
        </div>

        <div
          class="pill red"
          *ngIf="!promptData.equipmentDetails?.inverters?.quantity"
        >
          Unknown
        </div>
      </div>

      <ng-container
        *ngIf="promptData.equipmentDetails?.inverters?.specifications?.length"
      >
        <ul>
          <li
            *ngFor="let item of promptData.equipmentDetails?.inverters?.specifications"
            [innerText]="item"
          ></li>
        </ul>
      </ng-container>

      <div
        class="text-and-crumb"
      >
        Batteries

        <div
          class="pill gold"
          *ngIf="promptData.equipmentDetails?.batteries?.quantity"
        >
          {{(+promptData.equipmentDetails?.batteries?.quantity).toLocaleString()}} batteries
        </div>

        <div
          class="pill red"
          *ngIf="!promptData.equipmentDetails?.batteries?.quantity"
        >
          Unknown
        </div>
      </div>

      <ng-container
        *ngIf="promptData.equipmentDetails?.batteries?.specifications?.length"
      >
        <ul>
          <li
            *ngFor="let item of promptData.equipmentDetails?.batteries?.specifications"
            [innerText]="item"
          ></li>
        </ul>
      </ng-container>
    </div>
  </div>
  <div
    class="left-right-explanation"
  >
    <div
      class="left"
    ></div>

    <div
      class="right gold"
    >
      <h3>
        <div class="icon">
          <i class="fa-regular fa-bolt"></i>
        </div>

        Guarantees
      </h3>

      <div
        class="text-and-crumb"
      >
        First-Year Guaranteed Output

        <div
          class="pill gold"
          *ngIf="promptData.outputDetails?.firstYear?.kwhOutputGuarantee"
        >
          {{(+promptData.outputDetails?.firstYear?.kwhOutputGuarantee).toLocaleString()}} kWh
        </div>

        <div
          class="pill red"
          *ngIf="!promptData.outputDetails?.firstYear?.kwhOutputGuarantee"
        >
          None
        </div>
      </div>

      <ng-container
        *ngIf="promptData.outputDetails?.refundIfKwhOutputDoesNotMeetExpectations?.isOffered"
      >
        <p class="smaller">
          The provider additionally offers a refund if output does not meet expectations. Here are some of the stipulations:
        </p>

        <kallo-bar-item-numbered-list
          *ngFor="let text of promptData.outputDetails?.refundIfKwhOutputDoesNotMeetExpectations?.stipulations; let i = index"
          [subText]="text"
          [number]="i + 1"
          [color]="'lime'"
        ></kallo-bar-item-numbered-list>
      </ng-container>

      <ng-container
        *ngIf="!promptData.outputDetails?.refundIfKwhOutputDoesNotMeetExpectations?.isOffered"
      >
        <p class="smaller">
          Our AI did not find any language regarding a refund if the system underperforms.
        </p>
      </ng-container>
    </div>
  </div>
</div>


<div class="divider"></div>


<div class="section">
  <kallo-crumbs
    [crumbs]="crumbs.customerResponsibilities"
    [color]="'green'"
  ></kallo-crumbs>

  <div
    class="left-right-explanation"
  >
    <div
      class="left"
    >
      <h2>
        Who's Responsible?

        <div class="dynamic-arrow">
          <i class="fa-regular fa-circle-arrow-right"></i>
        </div>
      </h2>
    </div>

    <div
      class="right purple"
    >
      <h3>
        <div class="icon">
          <i class="fa-regular fa-dumpster"></i>
        </div>

        System Removal/Disposal
      </h3>

      <p
        class="text-and-crumb"
      >
        Who is responsible?
      </p>

      <ul>
        <li
          *ngFor="let item of promptData.systemRemoval?.stipulations"
          [innerText]="item"
        ></li>
      </ul>

      <kallo-bar-item-numbered-list
        [color]="'red'"
        [text]="'Why does this matter?'"
        [subText]="'Disposal of solar panels can often be a monumental task. Aside from the dangerous labor required, solar panels usually contain toxic materials that make finding a place to dump them a very difficult task. Be sure you understand who will be responsible for removing your solar system when it reaches end of life!'"
        [icon]="'face-thinking'"
      ></kallo-bar-item-numbered-list>

    </div>
  </div>


  <div
    class="left-right-explanation"
  >
    <div
      class="left"
    ></div>

    <div
      class="right purple"
    >
      <h3>
        <div class="icon">
          <i class="fa-regular fa-file-certificate"></i>
        </div>

        Permits & Metering
      </h3>

      <div
        class="text-and-crumb"
      >
        Who Obtains Permits?

        <div
          class="pill"
          [class.gold]="promptData.permitsAndMetering?.permits?.customerResponsibilities?.length"
          [class.green]="!promptData.permitsAndMetering?.permits?.customerResponsibilities?.length"
          *ngIf="promptData.permitsAndMetering?.permits?.providerObtainsAllPermits"
        >
          {{promptData.permitsAndMetering?.permits?.customerResponsibilities?.length ? 'Provider+Customer' : 'Provider'}}
        </div>

        <div
          class="pill red"
          *ngIf="!promptData.permitsAndMetering?.permits?.providerObtainsAllPermits"
        >
          Customer
        </div>
      </div>

      <ul>
        <li
          *ngFor="let item of promptData.permitsAndMetering?.permits?.customerResponsibilities"
          [innerText]="item"
        ></li>
      </ul>

      <div
        class="text-and-crumb"
      >
        Who Sets Up Net Metering?

        <div
          class="pill"
          [class.gold]="promptData.permitsAndMetering?.netMetering?.customerResponsibilities?.length"
          [class.green]="!promptData.permitsAndMetering?.netMetering?.customerResponsibilities?.length"
          *ngIf="promptData.permitsAndMetering?.netMetering?.providerObtainsApproval"
        >
          {{promptData.permitsAndMetering?.netMetering?.customerResponsibilities?.length ? 'Provider+Customer' : 'Provider'}}
        </div>

        <div
          class="pill red"
          *ngIf="!promptData.permitsAndMetering?.netMetering?.providerObtainsApproval"
        >
          Customer
        </div>
      </div>

      <ul>
        <li
          *ngFor="let item of promptData.permitsAndMetering?.netMetering?.customerResponsibilities"
          [innerText]="item"
        ></li>
      </ul>

    </div>
  </div>

  <div
    class="left-right-explanation"
  >
    <div
      class="left"
    ></div>

    <div
      class="right purple"
    >
      <h3>
        <div class="icon">
          <i class="fa-regular fa-user-helmet-safety"></i>
        </div>

        Subcontractors
      </h3>

      <kallo-bar-item-numbered-list
        [color]="'gold'"
        [icon]="'info'"
        [subText]="'Our AI did not find any language indicating that subcontractors will be used. Still, you should ask the provider so you can be sure you know who is entering your home.'"
        *ngIf="promptData.subcontractors?.subcontractors?.areAllowed === null"
      ></kallo-bar-item-numbered-list>

      <ng-container
        *ngIf="promptData.subcontractors?.subcontractors?.areAllowed !== null"
      >
        <div
          class="text-and-crumb"
        >
          May Be Used For Installation

          <div
            class="pill"
            [class.gold]="promptData.subcontractors?.subcontractors?.areAllowed"
            [class.green]="!promptData.subcontractors?.subcontractors?.areAllowed"
          >
            {{promptData.subcontractors?.subcontractors?.areAllowed ? 'Yes' : 'No'}}
          </div>
        </div>

        <div
          *ngIf="promptData.subcontractors?.subcontractors?.areAllowed"
          class="text-and-crumb"
        >
          Must Be Licensed

          <div
            class="pill"
            [class.red]="!promptData.subcontractors?.subcontractors?.mustBeLicensed"
            [class.green]="promptData.subcontractors?.subcontractors?.mustBeLicensed"
          >
            {{promptData.subcontractors?.subcontractors?.mustBeLicensed ? 'Yes' : 'Not Required'}}
          </div>
        </div>

        <div
          *ngIf="promptData.subcontractors?.subcontractors?.areAllowed"
          class="text-and-crumb"
        >
          Must Be Insured

          <div
            class="pill"
            [class.red]="!promptData.subcontractors?.subcontractors?.mustBeInsured"
            [class.green]="promptData.subcontractors?.subcontractors?.mustBeInsured"
          >
            {{promptData.subcontractors?.subcontractors?.mustBeInsured ? 'Yes' : 'Not Required'}}
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div
    class="left-right-explanation"
  >
    <div
      class="left"
    ></div>

    <div
      class="right purple"
    >
      <h3>
        <div class="icon">
          <i class="fa-regular fa-list-check"></i>
        </div>

        Other Customer Responsibilities
      </h3>

      <ul>
        <li
          *ngFor="let item of promptData.customerResponsibilities?.customerResponsibilities"
          [innerText]="item"
        ></li>
      </ul>
    </div>
  </div>
</div>


<div class="divider"></div>


<div class="section">
  <kallo-crumbs
    [crumbs]="crumbs.deedRestrictions"
    [color]="'green'"
  ></kallo-crumbs>

  <h2>
    Home Sale Restrictions <span class="icon blue"><i class="fa-regular fa-circle-arrow-down"></i></span>
  </h2>

  <div
    class="left-right-explanation"
  >
    <div
      class="right blue"
    >
      <h3>
        <div class="icon">
          <i class="fa-regular fa-sign-hanging"></i>
        </div>

        Selling a Solar Home
      </h3>

      <p>
        Background Information
      </p>

      <p class="smaller">
        When a homeowner enters into a residential solar contract, it often entails a long-term agreement, typically spanning 20 to 25 years, wherein the solar provider installs, maintains, and possibly finances the solar panels on the property. This arrangement can impose certain limitations on the sale of the house. Firstly, potential buyers must either agree to assume the remaining terms of the solar contract or the seller must pay off any outstanding obligations, which could reduce the net proceeds from the sale.<br><br><strong>This might limit the pool of interested buyers</strong>, especially if they're unfamiliar with or hesitant about solar agreements. Furthermore, if the solar system is leased and not owned, it may not add the same value to the home compared to a purchased system. Lastly, there can be complications with home appraisals due to varied understanding by appraisers of the value solar systems bring to a property. Consequently, while solar contracts can offer numerous benefits, they can also introduce complexities to the home selling process that homeowners should be aware of.
      </p>
    </div>

    <div
      class="right blue"
    >
      <h3>
        <div class="icon">
          <i class="fa-regular fa-house-lock"></i>
        </div>

        Your Contract
      </h3>

      <ng-container
        *ngIf="homeSaleMayBeRestricted"
      >
        <kallo-bar-item-numbered-list
          [color]="'red'"
          [icon]="'lock'"
          [text]="'Restrictions Found'"
          [subText]="'You likely must obtain consent to sell your home from this provider. Consult a licensed attorney for more information. Remember, SunScreener does not give legal advice.'"
        ></kallo-bar-item-numbered-list>

        <ng-container
          *ngIf="promptData.homeSaleRestrictions?.listOfRequirementsBuyerMustSatisfy?.length"
        >
          <p class="smaller">
            In order to sell to a prospective buyer, we found these requirements:
          </p>

          <ul>
            <li
              *ngFor="let item of promptData.homeSaleRestrictions?.listOfRequirementsBuyerMustSatisfy"
              [innerText]="item"
            ></li>
          </ul>
        </ng-container>

        <ng-container
          *ngIf="promptData.homeSaleRestrictions?.penaltiesIfSoldWithoutPermission?.areDefined"
        >
          <p class="smaller">
            If you do not obtain consent from the provider for the buyer to take over the contract:
          </p>

          <ul>
            <li
              *ngFor="let item of promptData.homeSaleRestrictions?.penaltiesIfSoldWithoutPermission.details"
              [innerText]="item"
            ></li>
          </ul>
        </ng-container>
      </ng-container>

      <ng-container
        *ngIf="!homeSaleMayBeRestricted"
      >
        <kallo-bar-item-numbered-list
          [color]="'green'"
          [icon]="'lock-open'"
          [text]="'Looks Good'"
          [subText]="'Our AI did not find restrictions that would hinder the sale of your home.'"
        ></kallo-bar-item-numbered-list>

        <kallo-bar-item-numbered-list
          [color]="'gold'"
          [icon]="'info'"
          [subText]="'Even though our AI did not find anything, you should always validate these findings with a licensed attorney. Remember, SunScreener does not give legal advice.'"
        ></kallo-bar-item-numbered-list>
      </ng-container>
    </div>
  </div>
</div>


<div class="divider"></div>


<div class="section">
  <kallo-crumbs
    [crumbs]="crumbs.breachAndDefault"
    [color]="'green'"
  ></kallo-crumbs>

  <h2>
    Legal Traps <span class="icon blue"><i class="fa-regular fa-circle-arrow-down"></i></span>
  </h2>

  <div
    class="left-right-explanation"
  >
    <div
      class="right purple"
    >
      <h3>
        <div class="icon">
          <i class="fa-regular fa-file-magnifying-glass"></i>
        </div>

        Stay Safe, Legally Speaking
      </h3>

      <p>
        Knowledge = Power
      </p>

      <p class="smaller">
        Not all contractual provisions are "traps" but often there are items in contracts that catch consumers off guard. What SunScreener aims to do in this section is shed light on some of the lesser-known rules customers may be agreeing to follow when they sign on the dotted line.
      </p>

      <p>
        Communication is Key
      </p>

      <p class="smaller">
        One of the best ways to avoid a legal dispute is to communicate, even after you may have mistakenly breached your contract.
      </p>

      <p class="smaller">
        An even better way to avoid a dispute is to avoid committing a breach. Use this section as a guide to some of the actions or events that may be viewed as a breach of your obligations.
      </p>
    </div>

    <div
      class="right red"
    >
      <h3>
        <div class="icon">
          <i class="fa-regular fa-ban"></i>
        </div>

        For This Contract...
      </h3>

      <p>
        Avoid Doing These Things
      </p>

      <p class="smaller">
        Aside from forgetting to do all of the things mentioned in the
        <em>Who's Responsible</em> section above, these additional items stood out as things you should avoid doing:
      </p>

      <ul>
        <li *ngIf="promptData.breachAndEarlyCancellation?.actionsThatCouldTriggerDefaultOrBreach?.failureToMakePayments">
          Failing to make payments as agreed
        </li>
        <li *ngIf="homeSaleMayBeRestricted">
          Sell your home without getting signoff from the provider
        </li>
        <li *ngIf="promptData.breachAndEarlyCancellation?.actionsThatCouldTriggerDefaultOrBreach?.makingModificationsToSystem">
          Making modifications to any of the installed equipment without getting provider approval
        </li>
        <li *ngIf="promptData.breachAndEarlyCancellation?.actionsThatCouldTriggerDefaultOrBreach?.notInformingProviderOfProblems">
          Failing to notify the provider when you become aware that the system is damaged, is not working properly, or otherwise is defective
        </li>
        <ng-container
          *ngIf="promptData.breachAndEarlyCancellation?.actionsThatCouldTriggerDefaultOrBreach?.other"
        >
          <li
            *ngFor="let item of promptData.breachAndEarlyCancellation?.actionsThatCouldTriggerDefaultOrBreach?.other"
            [innerText]="item"
          ></li>
        </ng-container>
      </ul>

      <ng-container
        *ngIf="hasNonBankingRelatedPenaltiesForBreach"
      >
        <p>
          Possible Consequences
        </p>

        <p class="smaller">
          The provider may take action in any of these ways, according to the terms of the contract:
        </p>

        <ul>
          <li *ngIf="promptData.breachAndEarlyCancellation?.penaltiesForDefaultOrBreachInclude?.removalOfSystem">
            Enter your property and remove the installed solar equipment from your property
          </li>
          <li *ngIf="promptData.breachAndEarlyCancellation?.penaltiesForDefaultOrBreachInclude?.paymentOfFutureFeesAccelerated">
            Force you to
            <em>immediately</em> pay some or all of the payments you would have otherwise had to pay over time
          </li>
          <li *ngIf="promptData.breachAndEarlyCancellation?.penaltiesForDefaultOrBreachInclude?.paymentOfProviderLegalFees">
            Pay for the provider's lawyers's fees and other legals fees that were incurred in the process of suing or collecting from you
          </li>
          <li *ngIf="promptData.breachAndEarlyCancellation?.penaltiesForDefaultOrBreachInclude?.reimbursementOfGovernmentIncentives">
            Force you to reimburse the provider for any utility, government, or other such incentives the provider was forced to pay back as a result of your breach or terminating your contract
          </li>
          <ng-container
            *ngIf="promptData.breachAndEarlyCancellation?.penaltiesForDefaultOrBreachInclude?.other"
          >
            <li
              *ngFor="let item of promptData.breachAndEarlyCancellation?.penaltiesForDefaultOrBreachInclude?.other"
              [innerText]="item"
            ></li>
          </ng-container>
        </ul>
      </ng-container>

      <ng-container
        *ngIf="hasBankingRelatedPenaltiesForBreach"
      >
        <p>
          Payment-Related Penalties
        </p>

        <ul>
          <li *ngIf="promptData.breachAndEarlyCancellation?.penaltiesForDefaultOrBreachInclude?.lateFees">
            For late payments, the provider may charge a late fee
          </li>
          <li *ngIf="promptData.breachAndEarlyCancellation?.penaltiesForDefaultOrBreachInclude?.chargebackFees">
            If you dispute payments to the provider with your banking or credit card companies, the provider may charge you to recoup the fees the provider incurs in relation to those chargebacks
          </li>
        </ul>
      </ng-container>

      <kallo-bar-item-numbered-list
        [status]="'warning'"
        [subText]="'These are not exhaustive lists. Any legal questions should be directed to a licensed attorney. Remember, SunScreener does not give legal advice.'"
      ></kallo-bar-item-numbered-list>
    </div>
  </div>
</div>


<div class="divider"></div>


<div class="section">
  <kallo-crumbs
    [crumbs]="crumbs.remedies"
    [color]="'green'"
  ></kallo-crumbs>

  <kallo-bar-item-numbered-list
    [status]="'error'"
    [text]="'Legal Disclaimer'"
    [subText]="legalDisclaimer"
    class="full-width"
  ></kallo-bar-item-numbered-list>

  <div
    class="left-right-explanation"
  >
    <div
      class="left"
    >
      <h2>
        If Things Go Wrong

        <div class="dynamic-arrow">
          <i class="fa-regular fa-circle-arrow-right"></i>
        </div>
      </h2>
    </div>

    <div
      class="right red"
    >
      <h3>
        <div class="icon">
          <i class="fa-regular fa-gavel"></i>
        </div>

        Legal Remedies
      </h3>

      <p class="smaller">
        If you cannot resolve a dispute with your provider, you may need to get a third party involved to review the situation and enforce a result.
      </p>

      <p class="smaller">
        But many contracts impose restrictions on
        <em>how</em> you may enforce your rights—and usually courts will enforce those restrictions.
      </p>

      <ng-container
        *ngIf="!hasLegalRemedyRestrictions"
      >
        <kallo-bar-item-numbered-list
          [status]="'complete'"
          [subText]="'Our AI did not find any of the typical legal remedy restrictions found in this contract. Remember, however, that SunScreener does not provide legal advice and that AI-based technologies are prone to error. Always consult with a licensed attorney for legal questions, such as this one.'"
        ></kallo-bar-item-numbered-list>
      </ng-container>

      <ng-container
        *ngIf="hasLegalRemedyRestrictions"
      >
        <p>
          Arbitration
        </p>

        <p class="smaller">
          In layman's terms, arbitration is asking a trusted person (the arbitrator) to settle an argument between two parties. This trusted person listens to both sides and decides who is right and/or how to fix the problem.<br><br>Arbitration may be considered undesirable because you are giving up rights you'd normally have in a traditional court of law, such as the right to appeal or the right to have your case heard in front of a jury.
        </p>

        <ng-container
          *ngIf="promptData.legalProvisions?.arbitration?.isRequired"
        >
          <kallo-bar-item-numbered-list
            *ngIf="!promptData.legalProvisions?.arbitration?.customerCanUnilaterallyOptOut"
            [color]="'red'"
            [icon]="'ban'"
            [text]="'Arbitration Required'"
            [subText]="'This contract will require you to submit to arbitration instead of using the traditional court system to resolve legal disputes. The arbitrator\'s decision is final. You may not opt out.'"
          ></kallo-bar-item-numbered-list>

          <kallo-bar-item-numbered-list
            *ngIf="promptData.legalProvisions?.arbitration?.customerCanUnilaterallyOptOut"
            [color]="'gold'"
            [icon]="'warning'"
            [text]="'Arbitration Required'"
            [subText]="'This contract will require you to submit to arbitration instead of using the traditional court system. The arbitrator\'s decision is final. You may be able, however, to opt out. Review your contract and/or consult with a licensed attorney to find out how.'"
          ></kallo-bar-item-numbered-list>
        </ng-container>

        <ng-container
          *ngIf="!promptData.legalProvisions?.arbitration?.isRequired"
        >
          <kallo-bar-item-numbered-list
            [color]="'green'"
            [icon]="'check'"
            [text]="'No Arbitration Requirement'"
            [subText]="'Our AI did not find a requirement for you to submit to arbitration to settle disputes.'"
          ></kallo-bar-item-numbered-list>
        </ng-container>


        <p>
          Class Actions
        </p>

        <p class="smaller">
          A class action is essentially a lawsuit that is brought by a large group of people who all have been wronged by a single party in the same way. Class actions can benefit consumers by making suing a party more affordable, efficient, and likely to succeed.
        </p>

        <kallo-bar-item-numbered-list
          *ngIf="promptData.legalProvisions?.classActionProhibited"
          [color]="'red'"
          [icon]="'ban'"
          [text]="'Class Actions Not Allowed'"
          [subText]="'This contract prohibits class actions. You must resolve legal disputes singularly, even if others are facing the exact same problem as you.'"
        ></kallo-bar-item-numbered-list>

        <ng-container
          *ngIf="!promptData.legalProvisions?.classActionProhibited"
        >
          <ng-container
            *ngIf="promptData.legalProvisions?.arbitration?.isRequired"
          >
            <kallo-bar-item-numbered-list
              *ngIf="!promptData.legalProvisions?.classActionProhibited"
              [color]="'gold'"
              [icon]="'warning'"
              [text]="'Class Action Restriction Implied'"
              [subText]="'Our AI did not find any explicit restrictions on class actions, but the presence of an arbitration requirement provision could, in fact, imply a class action restriction. Consult with a licensed attorney to determine whether this contract restricts your right to participate in a class action.'"
            ></kallo-bar-item-numbered-list>
          </ng-container>

          <ng-container
            *ngIf="!promptData.legalProvisions?.arbitration?.isRequired"
          >
            <kallo-bar-item-numbered-list
              *ngIf="!promptData.legalProvisions?.classActionProhibited"
              [color]="'green'"
              [icon]="'check'"
              [text]="'No Class Action Restriction'"
              [subText]="'Our AI did not find any restrictions on class actions.'"
            ></kallo-bar-item-numbered-list>
          </ng-container>
        </ng-container>


        <p>
          Jury Trial Prohibitions
        </p>

        <p class="smaller">
          A jury trial can benefit a consumer by having the facts of his or her case heard in front of a group of "normal, everyday people" instead of just a judge. Prohibitions against jury trials can impact a consumer's rights if a dispute goes to court.
        </p>

        <kallo-bar-item-numbered-list
          *ngIf="promptData.legalProvisions?.arbitration?.isRequired"
          [color]="'red'"
          [icon]="'ban'"
          [text]="'Arbitration Requirement Applies'"
          [subText]="'Because of the arbitration requirement, jury trials are automatically prohibited.'"
        ></kallo-bar-item-numbered-list>

        <ng-container
          *ngIf="!promptData.legalProvisions?.arbitration?.isRequired"
        >
          <kallo-bar-item-numbered-list
            *ngIf="promptData.legalProvisions?.juryTrialProhibited"
            [color]="'red'"
            [icon]="'ban'"
            [text]="'Jury Trial Not Allowed'"
            [subText]="'This contract prohibits jury trials. Lawsuit outcomes will be determined solely by a judge.'"
          ></kallo-bar-item-numbered-list>

          <kallo-bar-item-numbered-list
            *ngIf="!promptData.legalProvisions?.juryTrialProhibited"
            [color]="'green'"
            [icon]="'check'"
            [text]="'No Jury Trial Restriction'"
            [subText]="'Our AI did not find any restrictions on jury trials.'"
          ></kallo-bar-item-numbered-list>
        </ng-container>

        <ng-container
          *ngIf="promptData.legalProvisions?.stipulations?.length"
        >
          <p>
            Important Notes
          </p>

          <p class="smaller">
            Here are some important items we found pertaining to your remedy rights:
          </p>

          <ul>
            <li
              *ngFor="let item of promptData.legalProvisions.stipulations"
              [innerText]="item"
            ></li>
          </ul>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
