type DialogBoxButtonColor = '' | 'gray' | 'red' | 'green' | 'blue';


export interface DialogBoxButton {
  text: string;
  callback: (input?: string) => void;
  callbackIsDefault?: boolean; // set to true to execute this button's callback if user keys Enter while focused in input box
  color?: DialogBoxButtonColor;
  href?: {
    url: string;
    target?: '_self' | '_blank';
  };
  routerLink?: string;
}


export const DIALOG_BOX_BUTTON_OK: DialogBoxButton = {
  text: 'OK',
  callback: () => {
  },
  color: '',
};

export const DIALOG_BOX_BUTTON_CANCEL: DialogBoxButton = {
  text: 'Cancel',
  callback: () => {
  },
  color: 'gray',
};
