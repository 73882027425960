import {Injectable} from '@angular/core';
import {SessionService} from './session.service';
import {FeatureToggle, FeatureToggleName} from '../../lib/models/feature-toggle';
import {Observable} from 'rxjs';
import {PLATFORM_URL} from './env.service';


@Injectable({
  providedIn: 'root',
})
export class FeatureToggleService {

  constructor(
    private session: SessionService,
  ) {
  }


  getFeatureToggle$(name: FeatureToggleName): Observable<FeatureToggle> {
    return new Observable<FeatureToggle>(subscriber => {
      this.session.httpNoAuth.get<FeatureToggle[]>(`${PLATFORM_URL}/feature-toggles`).subscribe({
        next: (toggles) => {
          const toggle = toggles.find(value => {
            return value.name === name;
          });

          if (toggle) {
            subscriber.next(toggle);
            subscriber.complete();
          } else {
            subscriber.error(new Error('toggle not found'));
          }
        },
        error: err => {
          subscriber.error(err);
        },
      });
    });
  }
}
