import {Component, OnInit} from '@angular/core';
import {SessionService} from '../../../core/services/session.service';
import {OptionGroup} from '../../models/option';
import {UserService} from '../../../core/services/user.service';


@Component({
  selector: 'kallo-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  userMenuOptions: OptionGroup = new OptionGroup([
    {
      id: 'change-name',
      name: 'Change Name',
      callback: () => {
        this.userService.promptSetUserDisplayName$().subscribe();
      },
    },
    {
      id: 'logout',
      name: 'Log Out',
      callback: () => {
        this.session.logOut();
      },
    },
  ]);


  constructor(
    public session: SessionService,
    public userService: UserService,
  ) {
  }


  ngOnInit() {

  }


  activateUserMenu() {
    this.userMenuOptions.menuActive = true;
  }


  deactivateUserMenu() {
    this.userMenuOptions.menuActive = false;
  }
}
