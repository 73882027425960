<div
  class="upper"
>
  <img src="../../../assets/img/kallo-color-logo-purple.png">
</div>

<div
  class="main"
>
  <a
    class="item"
    *ngFor="let item of items"
    [routerLink]="item.href"
    [routerLinkActive]="'active'"
  >

    <div
      class="icon"
      [innerHtml]="'<i class=\'fa-regular '+item.icon+'\'>'"
    ></div>

    <div
      class="text"
      [innerText]="item.text"
    >
      Home
    </div>
  </a>
</div>

<div
  class="user"
>
  <kallo-user-avatar
    [showName]="true"
  ></kallo-user-avatar>
</div>
