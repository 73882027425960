import {Component, OnDestroy, OnInit} from '@angular/core';
import {SessionService} from '../../../core/services/session.service';
import {Subscription} from 'rxjs';
import {FeatureToggleService} from '../../../core/services/feature-toggle.service';
import {ShadeService} from '../../../core/services/shade.service';


@Component({
  selector: 'kallo-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  splashImage: string = "";
  copyrightYears: string = '';

  emailInput: string = '';

  loggingInWithEmail: boolean = false; // makes a form appear
  submittingEmail: boolean = false; // disables the form
  submittedEmail: boolean = false;

  isValidatingMagicLink: boolean = true; // makes a spinner appear
  private sub: Subscription;

  toggleInviteOnly: boolean;


  constructor(
    private featureToggleService: FeatureToggleService,
    private session: SessionService,
    private shade: ShadeService,
  ) {
    this.copyrightYears = this.calcCopyrightYears();

    const min: number = 1;
    const max: number = 4;

    const random = Math.floor(Math.random() * (max - min + 1)) + min;

    this.splashImage = `url('../../../assets/img/splash-fibonacci-${random}.jpg')`;
  }


  ngOnInit() {
    this.sub = this.session.isAuthenticating$.subscribe({
      next: (isValidatingMagicLink) => {
        this.isValidatingMagicLink = isValidatingMagicLink;
        this.loggingInWithEmail = isValidatingMagicLink;
      },
    });

    this.featureToggleService.getFeatureToggle$('NEW_USERS_INVITE_ONLY').subscribe({
      next: (toggle) => {
        this.toggleInviteOnly = toggle.value as boolean;
      },
      error: () => {
        this.shade.errorDialog('Something went wrong. Reload the page and try again.');
      },
    });
  }


  ngOnDestroy() {
    this.sub?.unsubscribe();
  }


  logInWithGoogle() {
    this.session.logInWithGoogle();
  }


  logInWithEmail() {
    this.submittingEmail = true;

    this.session.logInWithEmail$(this.emailInput).subscribe({
      next: () => {
        this.submittedEmail = true;
        this.submittingEmail = false;
      },
      error: (err) => {
        this.shade.errorDialog();
        this.submittingEmail = false;
      },
    });
  }


  private calcCopyrightYears(): string {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    if (currentYear === 2023) {
      return '' + currentYear;
    }

    return `2023-${currentYear}`;
  }
}
