import {Component, Input} from '@angular/core';


@Component({
  selector: 'kallo-floating-content-box',
  templateUrl: './floating-content-box.component.html',
  styleUrls: ['./floating-content-box.component.scss'],
})
export class FloatingContentBoxComponent {
  @Input() shadow: boolean = false;

}
