import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, switchMap, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {SessionService} from "../services/session.service";
import {fromPromise} from 'rxjs/internal/observable/innerFrom';


const AUTH_FAILURES_BEFORE_LOGOUT = 3;


@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(
    private session: SessionService,
  ) {
  }


  private authFailureCount: number = 0;


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return fromPromise(this.session.firebaseUser!.getIdToken()).pipe(
      switchMap(token => {
        if (token) {
          request = request.clone({headers: request.headers.set('Authorization', 'Bearer ' + token)});
        }

        return next.handle(request).pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.authFailureCount = 0;
            }

            return event;
          }),
          catchError((err) => {
            const bodyError: string = err?.error;

            if (bodyError.indexOf('authentication failed') === 0 || bodyError.indexOf('authentication header malformed') === 0) {
              this.authFailureCount++;
            }

            if (this.authFailureCount > AUTH_FAILURES_BEFORE_LOGOUT) {
              this.session.logOut();
            }

            return throwError(err);
          }),
        );
      }),
    );
  }
}
