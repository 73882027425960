<div
  [ngClass]="['crumbs', color]"
  *ngIf="crumbs && crumbs.length > 0"
>
  <div
    *ngFor="let crumb of crumbs; let i = index"
  >
    <a
      [routerLink]="crumb.route"
    >
      <i
        *ngIf="i > 0"
        class="fa-solid fa-chevron-right"
      ></i>
      {{crumb.name}}
    </a>
  </div>
</div>
