import {Component} from '@angular/core';


@Component({
  selector: 'kallo-flex-table',
  templateUrl: './flex-table.component.html',
  styleUrls: ['./flex-table.component.scss'],
})
export class FlexTableComponent {

}
