import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {SHADE_DIALOG_BOX, ShadeService, ShadeServiceProps, ShadeToolType} from '../../../core/services/shade.service';


@Component({
  selector: 'kallo-shade',
  templateUrl: './shade.component.html',
  styleUrls: ['./shade.component.scss'],
})
export class ShadeComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  SHADE_DIALOG_BOX = SHADE_DIALOG_BOX;

  currentShadeToolType: ShadeToolType = '';
  props: ShadeServiceProps = {};


  constructor(
    private shadeService: ShadeService,
  ) {
  }


  ngOnInit(): void {
    const sub = this.shadeService.incrementer.subscribe(
      () => {
        // Here we need to ensure that all properties of this class that are
        // references to properties in ShadeToolsService get set to the properties
        // in ShadeToolsService because we've just been informed that the service
        // has been instructed to render something new
        this.props = this.shadeService.props;
        this.currentShadeToolType = this.shadeService.currentShadeToolType;
      },
    );

    this.subs.push(sub);
  }


  ngOnDestroy() {
    this.subs.map(sub => sub.unsubscribe());
  }
}
