import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {DialogBoxButton} from '../../models/dialog-box';
import {ShadeService} from '../../../core/services/shade.service';


@Component({
  selector: 'kallo-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.scss'],
})
export class DialogBoxComponent implements OnInit, AfterViewInit {
  @Input() header: string = '';
  @Input() message: string = '';
  @Input() messageHtml: string = '';
  @Input() options: DialogBoxButton[] = [];
  @Input() enableInput: boolean = false;
  @Input() passwordInput: boolean = false;
  @Input() closeOnButtonClick: boolean = true;

  buttonSpinners: boolean[] = [];

  public userInput: string = '';

  @ViewChild('dialogBoxInput') private inputEl: ElementRef<HTMLInputElement>;


  constructor(
    private shadeService: ShadeService,
  ) {
  }


  ngOnInit(): void {
    for (let option in this.options) {
      this.buttonSpinners.push(false);
    }
  }


  ngAfterViewInit() {
    this.inputEl?.nativeElement.focus();
  }


  pressedEnter(): void {
    const defaultOptionIndex: number = this.options.findIndex(o => o.callbackIsDefault);

    if (defaultOptionIndex > -1) {
      const defaultOption: DialogBoxButton = this.options[defaultOptionIndex];
      this.execute(defaultOption.callback, defaultOptionIndex);
    }
  }


  /**
   *
   * @param func {(input?: string) => void} The optional input arg only works
   *    if this.enableInput is set to true
   * @param optionIndex {number}
   */
  execute(func: (input?: string) => void, optionIndex: number): void {
    this.buttonSpinners[optionIndex] = true;

    if (this.closeOnButtonClick) {
      this.shadeService.closeAll();
    }

    setTimeout(() => { // timeout needed to get spinner moving
      func(this.userInput);
    }, 0);
  }
}
