import {Injectable} from '@angular/core';
import {CanActivate, CanActivateChild, Router} from '@angular/router';
import {SessionService} from '../services/session.service';


@Injectable({
  providedIn: 'root',
})
export class NotAuthedGuard implements CanActivate, CanActivateChild {
  constructor(
    private session: SessionService,
    private router: Router,
  ) {
  }


  canActivate(): boolean {
    if (!this.session.isAuthenticated) {
      return true;
    }

    this.router.navigate(['/home']);
    return false;
  }


  canActivateChild(): boolean {
    return this.canActivate();
  }
}
