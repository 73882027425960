import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import {User} from '../../lib/models/user';
import {PLATFORM_URL} from './env.service';
import {ShadeService} from './shade.service';
import {DialogBoxButton} from '../../lib/models/dialog-box';
import {SessionService} from './session.service';


@Injectable({
  providedIn: 'root',
})
export class UserService {

  constructor(
    private session: SessionService,
    private shade: ShadeService,
    private http: HttpClient,
  ) {
  }


  sendInvitation$(emailAddress: string) {
    return this.http.post(`${PLATFORM_URL}/invitations`, null, {
      params: {
        email: emailAddress,
      },
    });
  }


  /**
   *
   * @param firstTime - If true, the user will be forced to set a name and
   *    the prompting is slightly different
   * @return {Observable<boolean|null>} Emits a boolean to represent success
   *    (true) or failure (false).
   */
  promptSetUserDisplayName$(firstTime: boolean = false): Observable<boolean> {
    return new Observable<boolean>(subscriber => {
      let header: string = '<i class="fa-regular fa-signature"></i> Set Display Name';
      let message: string = `Can you please provide us with your name? You can always change this later.`;

      const buttons: DialogBoxButton[] = [
        {
          text: 'Save Name',
          callback: (input) => {
            this.http.patch(`${PLATFORM_URL}/users/${this.session.user.id}`, {
              displayName: input,
            }).subscribe({
              next: () => {
                this.shade.closeAll();
                this.session.firebaseUser.reload().then(() => {
                  subscriber.next(true);
                  subscriber.complete();
                });
              },
              error: (err) => {
                this.shade.errorDialog();
                subscriber.error(err);
              },
            });
          },
          callbackIsDefault: true,
        },
      ];

      if (!firstTime) {
        header = '<i class="fa-regular fa-signature"></i> Change Display Name';
        message = `Tell us what you'd like us to call you. You can always change this later.`;

        buttons.push({
          text: 'Cancel',
          callback: () => {
            this.shade.closeAll();
            subscriber.next(false);
            subscriber.complete();
          },
          color: 'gray',
        });
      }

      this.shade.startDialog(
        header,
        message,
        buttons,
        {
          enableInput: true,
          closeOnButtonClick: false,
        },
      );
    });
  }
}
