<div
  [class.checked]="checked"
>
  <div
    *ngIf="checked"
    (click)="uncheck()"
    class="left"
  >
    <i class="fa-sharp fa-solid fa-square-check"></i>
  </div>

  <div
    *ngIf="!checked"
    (click)="check()"
    class="left"
  >
    <i class="fa-sharp fa-light fa-square"></i>
  </div>

  <div class="right">
    {{text}}

    <div
      *ngIf="subtext"
      class="subtext"
      [innerHtml]="subtext"
    ></div>
  </div>
</div>
