import {Component, Input} from '@angular/core';


@Component({
  selector: 'kallo-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @Input() numerator: number = 0;
  @Input() denominator: number = 0;
  @Input() zeroProgressText: string = 'Getting Started';

}
