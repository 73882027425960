<div
  class="gallery"
>
  <kallo-contract-gallery-item
    *ngFor="let contract of contracts"
    [contract]="contract"
  ></kallo-contract-gallery-item>
</div>

<button
  *ngIf="contracts && contracts.length"
  (click)="createContract()"
>
  Add Another Contract
</button>

<button
  *ngIf="contracts && !contracts.length"
  (click)="createContract()"
>
  Add My First Contract
</button>
