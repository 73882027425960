<div
  class="shade-host"
  *ngIf="currentShadeToolType!==''"
>
  <div class="shade-bkgd">
    <kallo-dialog-box
      *ngIf="currentShadeToolType===SHADE_DIALOG_BOX"
      [header]=props.header
      [message]=props.message
      [messageHtml]=props.messageHtml
      [options]=props.dialogOptions
      [enableInput]=props.enableInput
      [passwordInput]=props.password
      [closeOnButtonClick]=props.closeOnButtonClick
    ></kallo-dialog-box>
  </div>
</div>
