import {Component, Input} from '@angular/core';
import {EquipmentWarranty, GeneralWarranty, Payment, PromptData} from '../../models/contract';
import {Link} from '../../models/link';


@Component({
  selector: 'kallo-contract-solar-results',
  templateUrl: './contract-solar-results.component.html',
  styleUrls: ['./contract-solar-results.component.scss'],
})
export class ContractSolarResultsComponent {
  @Input() promptData: PromptData;


  introItems: Link[] = [
    {
      name: 'Hidden Costs',
      icon: `fa-magnifying-glass-dollar`,
    },
    {
      name: 'Home Sale Restrictions',
      icon: `fa-house-lock`,
    },
    {
      name: 'Legal Traps',
      icon: `fa-bomb`,
    },
    {
      name: 'Legal Waivers',
      icon: `fa-scale-unbalanced`,
    },
    {
      name: 'Warranties & Exclusions',
      icon: `fa-screwdriver-wrench`,
    },
    {
      name: 'Solar Panel Removal',
      icon: `fa-dumpster`,
    },
    {
      name: 'Subcontractors',
      icon: `fa-user-helmet-safety`,
    },
  ];


  get transactionType(): 'lease' | 'purchase' | 'ppa' | 'solar-loan' | '' {
    if (this.promptData.transactionType.isLease) {
      return 'lease';
    }

    if (this.promptData.transactionType.isPurchase) {
      if (this.promptData.paymentDetails?.monthlyPaymentDollars) {
        return 'solar-loan';
      } else {
        return 'purchase';
      }
    }

    if (this.promptData.transactionType.isPowerPurchaseAgreement) {
      return 'ppa';
    }

    return '';
  }


  get paymentSchedule(): Payment[] {
    const payments: Payment[] = [];

    for (let payment of this.promptData.paymentDetails?.requiredInitialPayments) {
      if (payment.dollarAmount) {
        payments.push(payment);
      }
    }

    return payments;
  }


  get generalEquipmentWarranty(): GeneralWarranty {
    return this.promptData.warranties?.equipmentWarranty;
  }


  /**
   * These are different from the general equipment warranty
   */
  get specificEquipmentWarranties(): EquipmentWarranty[] {
    const warranties: EquipmentWarranty[] = [];

    if (this.promptData.warranties?.solarPanelWarranty?.years) {
      warranties.push(this.promptData.warranties?.solarPanelWarranty);
    }

    if (this.promptData.warranties?.inverterWarranty?.years) {
      warranties.push(this.promptData.warranties?.inverterWarranty);
    }

    if (this.promptData.warranties?.batteryWarranty?.years) {
      warranties.push(this.promptData.warranties?.batteryWarranty);
    }

    return warranties;
  }


  get otherCostsInfo(): string[] {
    const costs: string[] = [];

    if (this.promptData.paymentDetails?.inspectionCosts) {
      costs.push(...this.promptData.paymentDetails?.inspectionCosts);
    }

    if (this.promptData.paymentDetails?.permitCosts) {
      costs.push(...this.promptData.paymentDetails?.permitCosts);
    }

    if (this.promptData.paymentDetails?.discounts) {
      costs.push(...this.promptData.paymentDetails?.discounts);
    }

    return costs;
  }


  /**
   * This returns true if there are any penalties for breach, except banking charges and late
   * fees, which are covered in a separate penalties section.
   */
  get hasNonBankingRelatedPenaltiesForBreach(): boolean {
    if (this.promptData.breachAndEarlyCancellation?.penaltiesForDefaultOrBreachInclude?.reimbursementOfGovernmentIncentives) {
      return true;
    }

    if (this.promptData.breachAndEarlyCancellation?.penaltiesForDefaultOrBreachInclude?.removalOfSystem) {
      return true;
    }

    if (this.promptData.breachAndEarlyCancellation?.penaltiesForDefaultOrBreachInclude?.paymentOfProviderLegalFees) {
      return true;
    }

    if (this.promptData.breachAndEarlyCancellation?.penaltiesForDefaultOrBreachInclude?.paymentOfFutureFeesAccelerated) {
      return true;
    }

    return !!this.promptData.breachAndEarlyCancellation?.penaltiesForDefaultOrBreachInclude?.other?.length;
  }


  get hasBankingRelatedPenaltiesForBreach(): boolean {
    if (this.promptData.breachAndEarlyCancellation?.penaltiesForDefaultOrBreachInclude?.lateFees) {
      return true;
    }

    if (this.promptData.breachAndEarlyCancellation?.penaltiesForDefaultOrBreachInclude?.chargebackFees) {
      return true;
    }

    return false;
  }


  get hasLegalRemedyRestrictions(): boolean {
    if (this.promptData.legalProvisions?.arbitration?.isRequired) {
      return true;
    }

    if (this.promptData.legalProvisions?.classActionProhibited) {
      return true;
    }

    if (this.promptData.legalProvisions?.juryTrialProhibited) {
      return true;
    }

    if (this.promptData.legalProvisions?.stipulations?.length) {
      return true;
    }

    return false;
  }


  get homeSaleMayBeRestricted(): boolean {
    return this.promptData.homeSaleRestrictions?.restrictionsCanImpedeHomeSale
      || this.promptData.breachAndEarlyCancellation?.actionsThatCouldTriggerDefaultOrBreach?.sellingHomeWithoutPermission;
  }


  legalDisclaimer: string = `SunScreener never provides legal advice. Always consult with a licensed attorney for any legal questions you have.`;
  deedRestrictionsDisclaimer: string = `This section pertains to restrictions placed on the deed of your home. These restrictions can prevent you from selling your house in the future. It is very important to understand whether your contract imposes restrictions on your deed before signing. Always consult with a licensed attorney for legal questions. SunScreener does not provide legal advice.`;
  aiDisclaimer: string = `SunScreener's technology is breakthrough, but remember that the results shown are generated by experimental AI technology, which usually gets things right but can also get things wrong. You should use SunScreener as one tool of many in your solar journey. Good luck!`;

  crumbs: {
    contractBasics: Link[],
    costBreakdown: Link[],
    warranties: Link[],
    equipmentSpecifications: Link[],
    customerResponsibilities: Link[],
    deedRestrictions: Link[],
    breachAndDefault: Link[],
    remedies: Link[],
  } = {
    contractBasics: [
      {
        name: 'Basics',
      },
      {
        name: 'Transaction Type',
      },
    ],
    costBreakdown: [
      {
        name: 'Basics',
      },
      {
        name: 'Cost Breakdown',
      },
    ],
    warranties: [
      {
        name: 'Provider Obligations',
      },
      {
        name: 'Warranty Information',
      },
    ],
    equipmentSpecifications: [
      {
        name: 'Specifications',
      },
      {
        name: 'Equipment + Output',
      },
    ],
    customerResponsibilities: [
      {
        name: 'Obligations',
      },
      {
        name: 'Who\'s Responsible?',
      },
    ],
    deedRestrictions: [
      {
        name: 'Legal',
      },
      {
        name: 'Home Sale Restrictions',
      },
    ],
    breachAndDefault: [
      {
        name: 'Legal',
      },
      {
        name: 'Breach and Default',
      },
    ],
    remedies: [
      {
        name: 'Legal',
      },
      {
        name: 'Remedies — Court & Arbitration',
      },
    ],
  };
  protected readonly prompt = prompt;
}
