import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class ClickService {
  click$: Subject<HTMLElement> = new Subject<HTMLElement>();


  constructor() {
  }
}
