import {Component, Input} from '@angular/core';


@Component({
  selector: 'kallo-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss'],
})
export class NotificationBarComponent {
  @Input() color: 'orange' | 'red' | 'blue' | 'fuchsia' | 'green';
}
