import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SessionService} from '../../../core/services/session.service';
import {Subscription} from 'rxjs';
import {User} from 'firebase/auth';


@Component({
  selector: 'kallo-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnInit, OnDestroy {
  private userSub: Subscription;
  private user: User | null = null;

  userImageSource: string = '';
  userFullName: string = '';

  @Input() showName: boolean = false;


  constructor(
    private session: SessionService,
  ) {
  }


  ngOnInit() {
    this.userSub = this.session.isAuthenticated$.subscribe({
      next: (loggedIn) => {
        if (loggedIn) {
          this.user = this.session.firebaseUser;
          this.userImageSource = this.user?.photoURL ?? '';
          this.userFullName = this.user?.displayName ?? '';
        }
      },
    });
  }


  ngOnDestroy() {
    this.userSub?.unsubscribe();
  }
}
