export type OptionId = string;


export interface Option {
  id: OptionId;
  name: string;
  href?: string;
  callback?: () => void;
  selected?: boolean;
}


export class OptionGroup {
  /**
   * This is just a convenient place for Angular templates to store whether
   * a menu should be open or not (instead of, e.g., having to have dedicated
   * component properties tracking whether a menu should be open)
   */
  menuActive: boolean = false;
  options: Option[] = [];


  constructor(options?: Option[]) {
    if (options) {
      this.options = options;
    }
  }


  changeSelected(id: string) {
    for (let option of this.options) {
      if (option.id === id) {
        option.selected = true;
      } else {
        option.selected = false;
      }
    }
  }
}
